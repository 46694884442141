a {
  color: $cl-black;
  transition: all .2s ease;
}

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

html,
body {
  height: auto;

  @include respond-to('desktop') {
    overflow-x: hidden;
  }

  .disabled-scroll {
    position: fixed;
    height: 100vh;
    overflow-y: hidden;
  }
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;

  &.body-vn {
    font-family: $font-family-roboto;

    .dc-footer__left p {
      max-width: 500px;
    }

    .dc-page-culture__office-header {
      @include respond-to('desktop-large') {
        margin-top: -20px;
      }

      @include landscapeMode {
        margin-top: -20px;
      }
    }

    .dc-page-culture__office-intro {
      margin-top: 0;

      @include respond-to('mobile') {
        margin-top: 14px;
      }

      @include landscapeMode {
        margin-top: 14px;
      }
    }

    .dc-career-detail__apply .btn-secondary {
      font-size: 16px;
    }

    .dc-page-title,
    .dc-hero__content h1,
    .dc-menu__left .nav-link,
    .dc-newjob__header,
    .dc-heading__title,
    .dc-about__header,
    .dc-page-culture__office-header,
    .dc-career__join-header,
    .dc-career-detail__apply-btn,
    .dc-career-detail__backlist,
    .dc-news-detail__backlist,
    .dc-hire__step,
    .dc-banner__center .list-inline-item > a,
    .dc-about__office-item-title,
    .dc-about__direction-item-title,
    .dc-page-culture__item-title,
    .dc-page-culture__office-item-title,
    .dc-career__join-btn,
    .dc-home-blog__header,
    .dc-career-detail__content h4,
    .dc-career-detail__name h3,
    .dc-menu-mobile__navbar .nav-item .nav-link,
    .dc-career__center .list-inline-item a,
    .dc-career [class*=col-]:first-child .dc-newjob__item,
    .dc-career-detail__apply a,
    .dc-career-detail__apply .btn
    {
      font-family: $font-family-roboto;
      font-weight: $font-weight-bold;
    }

    .dc-heading__seemore,
    .dc-banner__content p,
    .dc-footer__link span,
    .dc-footer__social-list,
    .dc-newjob__item,
    .dc-home-blog__meta,
    .dc-home-blog__position,
    .dc-news__body,
    .dc-about__desc,
    .dc-about__office-item-desc,
    .dc-about__office-item-link,
    .dc-about__direction-item-info,
    .dc-career__join-desc,
    .dc-career-detail__name ul .list-inline-item,
    .dc-career-detail__content,
    .dc-career__modal-body,
    .dc-page-culture__office,
    .dc-policy,
    .dc-news-detail__name,
    .dc-news-detail__content,
    .dc-career__modal-title,
    .dc-hire__step span,
    .dc-faq__header,
    .dc-menu-mobile__navbar .nav-item>ul a
    {
      font-family: $font-family-roboto;
    }

    .dc-banner__content h1 {
      @include respond-to('mobile') {
        line-height: 50px;
      }
    }

    .dc-hire [class*=col]:first-child .dc-hire__step {
      @include respond-to('mobile') {
        margin-left: -22px;
      }

      @include landscapeMode {
        margin-left: -22px;
      }

      &::after {
        @include respond-to('mobile') {
          left: calc(50% + 30px);
        }

        @media only screen and ( orientation: landscape ) and (max-width : 812px) {
          left: calc(50% + 36px);
        }

        @media only screen and ( orientation: landscape ) and (max-width : 736px) {
          left: calc(50% + 30px);
        }

        @media only screen and ( orientation: landscape ) and (max-width : 667px) {
          left: calc(50% + 30px);
        }
      }
    }

    .dc-career [class*='col-']:first-child .dc-newjob__header {
      letter-spacing: 1px;

      @include respond-to('mobile') {
        line-height: 42px;
      }

      @include landscapeMode {
        line-height: 42px;
      }
    }

    .dc-faq__select {
      @include respond-to('mobile') {
        width: 160px;
      }
    }

    .dc-heading__seemore {
      @include respond-to('mobile') {
        min-width: 115px;
        margin-left: 16px;
        display: flex;
        justify-content: flex-end;
      }

      @include landscapeMode {
        min-width: 110px;
        margin-left: 16px;
      }
    }
  }
}

.dc-body {
  display: flex;
  flex-direction: column;

  &--career-detail {

    @include respond-to('mobile') {
      padding-bottom: 48px;
    }

    @include landscapeMode {
      padding-bottom: 48px;
    }
  }
}

.dc-main {
  flex-grow: 1;
  min-height: calc(100vh - 192px);

  @include respond-to('tablet') {
    min-height: calc(100vh - 248px);
  }
}

.container {
  @media(min-width: 1310px) {
    max-width: 1200px;
  }
}

.dc-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  @include respond-to('mobile') {
    margin-bottom: 30px;
    align-items: flex-end;
  }

  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-xxlarge;
    line-height: 40px;
    margin-bottom: 0;
    font-family: $font-family-lineseed-extra-bold;

    @include respond-to('mobile') {
      letter-spacing: -1.2px;
      line-height: 39px;
    }
  }

  &__seemore {
    font-size: $font-size-medium;
    font-family: $font-family-sf-pro-medium;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 12px;
    transition: all .2s ease;
    letter-spacing: -0.46px;
    font-weight: 500;

    &:hover {
      color: $cl-black;
    }

    @include respond-to('mobile') {
      line-height: 24px;
      padding-right: 12px;
    }

    @include landscapeMode {
      line-height: 24px;
      padding-right: 12px;
    }

    .dc-arrow {
      border-top: 1px solid $cl-black;
      border-left: 1px solid $cl-black;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(135deg);
    }
  }
}

.dc-arrow {
  transform: rotate(135deg);
  right: 20px;
  top: 28px;
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: transparent;
  border-top: 1px solid $cl-white;
  border-left: 1px solid $cl-white;
}

.dc-page-title {
  font-family: $font-family-lineseed-extra-bold;
  font-size: 70px;
  line-height: 76px;
  margin-bottom: 0;

  @include respond-to('mobile') {
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 24px;
    letter-spacing: -0.5px;
  }

  @include landscapeMode {
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 24px;
    letter-spacing: -0.5px;
  }
}

.dc-page-top {
  margin-bottom: 60px;

  @include respond-to('tablet') {
    margin-bottom: 0;
  }

  @include landcapeModeTable {
    margin-bottom: 0;
  }
}

@media (min-width: 570px) {
  .modal-dialog {
    max-width: 530px;
  }
}

[type="radio"]:checked, [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: $cl-black;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: $cl-white;
    border: 1px solid #d6d6d6;
    transition: all .2s ease;
  }
}

[type="radio"]:checked + label:before {
  background: $cl-green;
  border: 1px solid $cl-green;
}

.dc-page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  font-size: $font-size-large;
  color: $cl-black;
  padding: 16px;

  @include respond-to('tablet') {
    font-size: $font-size-medium;
    margin-top: 100px;
  }

  @include landcapeModeIphoneX {
    margin-top: 24px;
  }

  h2 {
    font-size: $font-size-xxlarge + 12;
    margin-bottom: 8px;

    @include respond-to('tablet') {
      font-size: $font-size-xxlarge;
    }
  }

  p {
    color: $cl-dark;
    margin-bottom: 32px;
    text-align: center;

    @include respond-to('tablet') {
      margin-bottom: 16px;
    }

    @include landcapeModeIphoneX {
      margin-bottom: 8px;
    }
  }

  a {
    text-decoration: underline;

    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}

.text-primary {
  color: #08b33b !important;
}


.btn-link:hover {
  color: $cl-black;
}

.form-group {
  margin-bottom: 0;
}

@include respond-to('mobile') {
  .wow {
    animation-name: none !important;
    visibility: visible !important;
  }
}

#inputPosition {
  margin-top: 2px;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: $cl-black;
}

.btn-primary {
  background-color: $cl-green;
  border-color: $cl-green;

  &:hover,
  &:active {
    background-color: $cl-green !important;
    border-color: $cl-green !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.owl-stage-outer {
  width: calc(100vw + 15px);
}

.owl-carousel .owl-stage {
  padding-left: 0 !important;
}

.lity {
  background: rgba($cl-black, .8);
}



.lity-close, .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
  @include respond-to('mobile') {
    position: absolute;
    top: -3rem;
  }

  @include landscapeMode {
    position: absolute;
    top: -3rem;
  }
}
