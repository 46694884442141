// Style for News Detail Page

.dc-news-detail {
  padding: 70px 0 0;

  @include respond-to('desktop-large') {
    padding: 50px 0 0;
  }

  &__name {
    font-family: $font-family-sf-pro;
  }

  &__top {
    border-bottom: 1px solid $cl-gray3;
    padding-bottom: 47px;
    margin-bottom: 6px;

    @include respond-to('mobile') {
      padding-bottom: 24px;
    }

    @media only screen and (max-width : 375px) {
      flex-direction: column;
    }
  }

  &__content {
    margin-top: 50px;
    font-family: $font-family-sf-pro;

    @include respond-to('mobile') {
      margin-top: 30px;
    }

    h4 {
      margin-bottom: 15px;
      font-weight: $font-weight-bold;
      font-size: $font-size-large - 2;
      font-family: $font-family-lineseed-bold;
    }

    ul {
      color: $cl-black2;
      font-size: $font-size-medium;
      line-height: 30px;
      font-weight: $font-weight-bold;
      list-style: none;

      li {
        position: relative;
        padding-left: 12px;

        &::after {
          position: absolute;
          content: '';
          left: 0;
          top: 12px;
          background: $cl-black2;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }
      }
    }

    p {
      color: $cl-dark;
      font-size: $font-size-medium;
      line-height: 30px;
      margin-bottom: 0;
    }

    img {
      width: 100% !important;
      max-width: 100% !important;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    font-size: $font-size-xxlarge + 2;
    font-weight: $font-weight-bold;
    line-height: 42px;
    font-family: $font-family-lineseed-bold;

    @include respond-to('desktop-large') {
      margin-bottom: 24px;
    }

    @include respond-to('mobile') {
      font-size: $font-size-xlarge;
    }
  }

  &__name {
    ul {
      font-size: $font-size-base;
      color: $cl-dark;
      margin-top: 8px;

      @include respond-to('desktop') {
        padding: 10px 0;
      }

      .list-inline-item {
        position: relative;

        &:not(:last-child) {
          margin-right: 21px;

          &::after {
            position: absolute;
            content: '';
            right: -11px;
            top: 5px;
            background: $cl-dark;
            width: 1px;
            height: 12px;
          }
        }
      }
    }
  }

  &__social {
    min-width: 145px;

    a {
      display: block;
    }

    img {
      max-width: 38px;
    }
  }

  &__bottom {
    border-top: 1px solid $cl-gray3;
    padding: 15px 0 50px;
    text-align: center;
    margin-top: 50px;

    @include respond-to('desktop-large') {
      padding: 15px 0 30px;
      margin-top: 24px;
    }
  }

  &__backlist {
    font-weight: $font-weight-bold;
    font-family: $font-family-sf-pro-bold;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end;
  }
}
