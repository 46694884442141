// Style for About Page

.dc-about {
  padding: 70px 0 100px;

  @include respond-to('mobile') {
    padding: 35px 0 21px;
  }

  .dc-page-title {
    margin-bottom: 24px;

    @include respond-to('mobile') {
      margin-bottom: 16px;
    }
  }

  .dc-arrow {
    border-top: 1px solid $cl-green;
    border-left: 1px solid $cl-green;
    top: 9px;
    left: calc(100% + 6px);
  }

  &__desc {
    font-size: $font-size-medium;
    font-family: $font-family-sf-pro;
    line-height: 21px;
    color: $cl-dark;
    margin-bottom: 57px;

    @include respond-to('mobile') {
      margin-bottom: 50px;
      line-height: 24px;
    }

    @include landscapeMode {
      margin-bottom: 50px;
      line-height: 24px;
    }
  }

  &__office {
    [class*='col-'] {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-item {
      color: $cl-black2;

      @include respond-to('desktop-large') {
        margin-bottom: 40px;
      }

      &-img {
        img {
          width: 100%;
          height: 330px;
          object-fit: cover;

          @include respond-to('mobile') {
            height: 200px;
          }
        }
      }

      &-title {
        font-size: $font-size-xlarge + 2;
        font-weight: $font-weight-bold;
        @include text-truncate;
        font-family: $font-family-sf-pro-bold;

        @include respond-to('mobile') {
          font-size: $font-size-large;
        }
      }

      &-body {
        padding: 24px 0 0;

        @include respond-to('mobile') {
          padding: 17px 0 0;
        }
      }

      &-desc {
        font-size: $font-size-medium;
        font-family: $font-family-sf-pro;
        line-height: 21px;
        margin: 13px 0 25px;

        @include respond-to('mobile') {
          margin: 7px 0 11px;
          font-size: $font-size-medium - 1;
        }
      }

      &-link {
        font-size: $font-size-medium;
        font-family: $font-family-sf-pro-medium;
        color: $cl-green;
        letter-spacing: 0.46px;
        position: relative;
        display: inline-block;
        font-weight: $font-weight-medium;

        @include respond-to('mobile') {
          letter-spacing: 0;
        }

        @include landscapeMode {
          letter-spacing: 0;
        }

        &:hover {
          color: $cl-green;
        }
      }
    }
  }

  &__header {
    margin-top: 82px;
    margin-bottom: 40px;
    font-size: $font-size-xxlarge;
    font-family: $font-family-lineseed-extra-bold;
    line-height: 40px;

    @include respond-to('desktop-large') {
      margin-top: 0px;
    }

    @include respond-to('mobile') {
      margin-bottom: 25px;
    }
  }

  &__direction {
    [class*='col-'] {
      &:nth-child(2) {
        margin-left: -1px;

        @include respond-to('desktop') {
          margin-left: 0;
          margin-top: -1px;
        }

        @include landcapeModeTable {
          margin-left: 0;
          margin-top: -1px;
        }
      }
    }

    &-item {
      border: 1px solid $cl-gray;
      padding: 50px;
      height: 100%;

      @include respond-to('desktop') {
        padding: 30px;
      }

      iframe {
        width: 100%;
        height: 273px;
        border: 0;
      }

      &-title {
        font-size: $font-size-xxlarge - 2;
        font-weight: $font-weight-bold;
        font-family: $font-family-sf-pro-bold;
        letter-spacing: -0.3px;
        color: $cl-black2;
        margin: 48px 0 24px;

        @include respond-to('mobile') {
          font-size: $font-size-xlarge + 2;
          margin: 24px 0;
          letter-spacing: 0;
          line-height: normal;
        }

        @include landscapeMode {
          font-size: $font-size-xlarge + 2;
          margin: 24px 0;
          letter-spacing: 0;
          line-height: normal;
        }
      }

      &-info {
        font-size: $font-size-medium;
        font-family: $font-family-sf-pro;
        line-height: 25px;
        color: $cl-dark;
        margin-bottom: 16px;
        display: flex;

        a {
          color: $cl-dark;

          &:hover {
            color: $cl-dark;
          }
        }

        img {
          min-width: 23px;
          width: 23px;
          height: 23px;
          object-fit: cover;
          margin-top: 2px;
          margin-right: 10px;
        }
      }

      &-social {
        margin-top: 32px;

        img {
          width: 38px;
          height: 38px;
          object-fit: cover;
        }
      }
    }
  }
}
