// Style for News Box Item

.dc-news {
  [class*='col-'] {
    align-self: flex-start;
    max-width: 550px;

    @include respond-to('desktop-large') {
      max-width: 100%;
    }

    img {
      height: 550px;

      @include respond-to('desktop-large') {
        height: 420px;
      }

      @include respond-to('mobile') {
        height: 160px;
      }

      @include landscapeMode {
        height: 160px;
      }
    }

    &:nth-child(3) {
      margin-top: -120px;

      @include respond-to('desktop-large') {
        margin-top: 0;
      }
    }

    &:first-child,
    &:last-child {
      img {
        height: 420px;

        @include respond-to('mobile') {
          height: 160px;
        }

        @include landscapeMode {
          height: 160px;
        }
      }
    }
  }

  &__item {
    margin-bottom: 120px;

    @include respond-to('desktop-large') {
      margin-bottom: 40px;
    }

    &:hover img {
      transform: scale(1.1);
    }
  }

  &__img {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1);
      transition: all .3s ease;
    }
  }

  &__body {
    color: $cl-dark;
    font-family: $font-family-sf-pro;
  }

  &__header {
    margin: 24px 0 21px;
    font-size: $font-size-xxlarge - 2;
    font-family: $font-family-sf-pro-bold;
    letter-spacing: -0.3px;

    @include respond-to('desktop') {
      font-size: $font-size-large;
      margin: 25px 0 10px;
    }

    @include respond-to('mobile') {
      margin: 16px 0 10px;
    }

    @include landscapeMode {
      margin: 16px 0 10px;
    }

    a {
      color: $cl-black2;

      &:hover {
        color: $cl-black;
      }
    }
  }

  &__meta {
    display: block;
    margin-bottom: 15px;
    line-height: 25px;
    font-size: $font-size-medium - 2;

    @include respond-to('desktop') {
      font-size: $font-size-medium - 1;
      margin-bottom: 10px;
    }

    @include respond-to('mobile') {
      line-height: 22px;
    }

    @include landscapeMode {
      line-height: 22px;
    }
  }

  &__desc {
    font-size: $font-size-medium;
    line-height: 25px;
    @include text-truncate($line: 3);

    @include respond-to('desktop') {
      font-size: $font-size-medium - 1;
      @include text-truncate($line: 5);
    }

    @include landscapeMode {
      @include text-truncate($line: 5);
      line-height: 22px;
    }
  }
}
