// Color variables

$cl-white: #fff;
$cl-black: #000;
$cl-black2: #1e1e1e;
$cl-dark: #616161;
$cl-gray: #e2e2e2;
$cl-brown-gray: #999;
$cl-gray2: #e1e1e1;
$cl-gray3: #e8e7e8;
$cl-gray4: #d8d8d8;
$cl-gray5: #fbfbfb;
$cl-gray6: #efefef;
$cl-gray7: #aaa;
$cl-gray8: #f0f0f0;
$cl-gray9: #f9f9f9;
$cl-gray10: #bfbfbf;
$cl-green: #08b33b;
$cl-red: #eb4e3d;
