/* MEDIA QUERIES */

@mixin respond-to($breakpoint) {
  @if $breakpoint== "small-mobile" {
    @media (max-width: 320px) {
      @content;
    }
  } @else if $breakpoint== "mobile" {
    @media (max-width: 480px) {
      @content;
    }
  }
  @else if $breakpoint== "tablet" {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint== "desktop" {
    @media (max-width: 969px) {
      @content;
    }
  } @else if $breakpoint== "desktop-large" {
    @media (max-width: 1170px) {
      @content;
    }
  } @else {
    @media ($breakpoint) {
      @content;
    }
  }
}

// Example usage
/* @include respond-to('mobile') {} */

@mixin landcapeModeTable() {
  @media only screen
  and (max-width: 1024px)
  and (orientation: landscape ) {
    @content;
  }
}

@mixin landcapeModeIphoneX() {
  @media only screen
  and (min-device-width : 375px)
  and (max-device-height : 812px)
  and (-webkit-device-pixel-ratio : 3)
  and (orientation: landscape ) {
    @content;
  }
}

@mixin landcapeModeIphone() {
  // iPhone 6+/6s+/7+/8+
  @media only screen
  and (device-width : 414px)
  and (device-height : 736px)
  and (-webkit-device-pixel-ratio : 3)
  and (orientation: landscape ){
    @content;
  }
}

@mixin landcapeModeIphoneSmall() {
  // Iphone 6, 6s, 7, 8
  @media only screen
  and (device-width : 375px) 
  and (device-height : 667px)
  and (-webkit-device-pixel-ratio : 2)
  and (orientation: landscape ) {
    @content;
  }
}

@mixin responsiveIphone() {
  // Iphone 6, 6s, 7, 8
  @media only screen
  and (device-width : 375px) 
  and (device-height : 667px) 
  and (-webkit-device-pixel-ratio : 2) {
    @content;
  }
}

@mixin landscapeMode() {
  @media only screen and ( orientation: landscape ) and (max-width : 812px) {
    @content;
  }

  @media only screen and ( orientation: landscape ) and (max-width : 736px) {
    @content;
  }

  @media only screen and ( orientation: landscape ) and (max-width : 667px) {
    @content;
  }
}

@mixin text-truncate($line: 2) {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin browser($browser) {
  @if $browser=="edge" {
    @supports (-ms-ime-align:auto) {
      @content;
    }
  }
  @else if $browser== "ie11" {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }
  }
  @else {
    @media ($browser) {
      @content;
    }
  }
}

// Example usage
/* @include browser('ie11') {} */
