// Style for Culture Page

.dc-page-culture {
  padding: 70px 0 100px;

  @include respond-to('desktop-large') {
    padding:  36px 0 50px;
  }

  &__welfare {
    [class*='col-'] {
      &:nth-child(2) {
        max-width: 490px;

        @include respond-to('desktop-large') {
          max-width: 100%;
        }
      }
    }
  }

  &__text {
    color: $cl-dark;
    font-size: $font-size-medium;
    line-height: 26px;
    letter-spacing: -0.16px;

    @include respond-to('mobile') {
      line-height: 24px;
      margin-bottom: 0;
    }

    @include landscapeMode {
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  ul {
    color: $cl-dark;
    font-size: $font-size-medium;
    margin-bottom: 0;
    line-height: 26px;
    letter-spacing: -0.16px;
    padding-left: 0;
    list-style: none;
  }

  &__intro {
    margin-bottom: 50px;

    @include respond-to('desktop-large') {
      margin-bottom: 34px;
      margin-top: 32px;
    }

    @include respond-to('mobile') {
      margin-top: 0;
    }

    @include landscapeMode {
      margin-top: 0;
    }
  }

  &__item {
    &-img {
      img {
        width: 100%;
        height: 494px;
        object-fit: cover;

        @include respond-to('desktop-large') {
          height: 240px;
        }
      }
    }

    &-body {
      padding: 33px 30px 100px 125px;

      @include respond-to('desktop-large') {
        padding: 30px 30px 70px 100px;
      }

      @include respond-to('desktop-large') {
        padding: 16px 0 40px;
      }
    }

    &-title {
      font-size: $font-size-xxlarge + 3;
      color: $cl-black2;
      font-weight: $font-weight-bold;
      margin-bottom: 0;
      letter-spacing: -0.35px;
      font-family: $font-family-sf-pro-bold;

      @include respond-to('desktop-large') {
        font-size: $font-size-large;
      }
    }

    &-category {
      color: $cl-green;
      margin: 22px 0;
      display: block;

      @include respond-to('desktop-large') {
        margin: 10px 0;
      }
    }
  }

  &__left {
    .dc-page-culture__item {
      &:nth-child(2) {
        padding-left: 125px;

        @include respond-to('desktop-large') {
          padding-left: 100px;
        }

        @include respond-to('desktop-large') {
          padding-left: 0;
        }

        .dc-page-culture__item-body {
          padding-left: 0;
        }

        .dc-page-culture__item-img {
          img {
            height: 480px;

            @include respond-to('desktop-large') {
              height: 240px;
            }
          }
        }
      }
    }
  }

  &__right {
    .dc-page-culture__item {
      &-body {
        padding-left: 0;
      }

      &-img {
        img {
          height: 620px;

          @include respond-to('desktop-large') {
            height: 240px;
          }
        }
      }

      &:nth-child(2) {
        .dc-page-culture__item-img {
          img {
            height: 480px;

            @include respond-to('desktop-large') {
              height: 240px;
            }
          }
        }
      }
    }
  }

  &__office {
    font-family: $font-family-sf-pro;

    .dc-page-culture__office-intro {
      @include respond-to('mobile') {
        margin-bottom: 0;
      }

      @include landscapeMode {
        margin-bottom: 0;
      }
    }

    &-banner {
      img {
        width: 100%;
        height: 280px;

        @include respond-to('desktop-large') {
          height: 150px;
        }
      }
    }

    &-intro {
      padding-left: 25px;
      margin-top: -72px;

      @include respond-to('mobile') {
        padding-left: 0;
      }

      + ul {
        padding-left: 25px;

        @include respond-to('mobile') {
          padding-left: 0;
        }

      }
    }

    &-header {
      font-size: 70px;
      font-family: $font-family-lineseed-extra-bold;
      line-height: 76px;
      margin-top: -32px;
      margin-bottom: 0;

      @include respond-to('desktop-large') {
        font-size: 40px;
        line-height: 42px;
        margin-top: -24px;
      }
    }

    &-intro {
      @include respond-to('desktop-large') {
        margin-top: 14px;
      }

      @include respond-to('desktop-large') {
        margin-bottom: 34px;
      }
    }

    &-item {
      &-title {
        font-size: $font-size-xxlarge;
        font-weight: $font-weight-bold;
        margin-bottom: 14px;
        font-family: $font-family-sf-pro-bold;
        line-height: 36px;

        @include respond-to('desktop-large') {
          font-size: $font-size-xlarge + 2;
          margin-bottom: 10px;
        }
      }

      &-body {
        padding: 0 100px 0 41px;
        border: 1px solid $cl-gray3;
        min-height: 360px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond-to('desktop-large') {
          padding: 28px 30px;
          min-height: 335px;
        }

        @include respond-to('desktop-large') {
          min-height: unset;
        }
      }

      &-img {
        img {
          width: 100%;
          height: 360px;
          object-fit: cover;

          @include respond-to('desktop-large') {
            height: 200px;
          }
        }
      }
    }

    &-content {
      .dc-page-culture__office-item {
        &:nth-child(2) {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
