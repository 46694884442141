// Style for header

.dc-header {
  &.fixed-header {
    background: $cl-white;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
    box-shadow: 0 0 5px rgba($cl-black, .1);
    animation: slideInDown .4s ease;

    .dc-menu-mobile .button-black {
      display: block;
    }

    .dc-menu-mobile .button-white {
      display: none;
    }

    .dc-menu-mobile .navbar-brand {
      color: $cl-black;
    }

    .dc-menu__left {
      .nav-item {
        &.out-link {
          background: url("../images/ic-outlink.svg") no-repeat;
          background-position: 95% 40%;
        }

        > ul {
          a:hover {
            color: $cl-black;
          }
        }
      }

      .nav-link {
        color: $cl-black;

        &::after {
          background: $cl-black;
        }
      }
    }

    .dc-menu__language {
      .nav-link {
        color: $cl-black;
      }

      .nav-item {
        .nav-link {
          color: rgba($cl-black, .3);
          transition: all .1s ease;

          &:hover {
            color: $cl-black;
          }
        }

        &.active {
          .nav-link {
            color: $cl-black;
          }
        }

        &:first-child {
          .nav-link {
            &::after {
              background: rgba($cl-black, .3);
            }
          }
        }
      }
    }
  }
}

.dc-menu {
  background: $cl-white;
  line-height: 5.18;
  padding: 0 67px 0 7px;
  position: relative;
  z-index: 5;

  .logo-white {
    display: none;
  }

  .logo-white,
  .logo-black
  {
    max-height: 20px;
  }

  @include respond-to('desktop') {
    display: none;
  }

  &__logo {
    margin-top: -2px;
  }

  &__left {
    .nav-item {
      position: relative;

      > ul {
        position: absolute;
        width: 300px;
        z-index: 10;
        border-radius: 2px;
        left: 20px;
        opacity: 0;
        visibility: hidden;
        transition: all .2s ease;
        transform: translateY(0);

        li {
          line-height: 32px;
        }

        a {
          color: #999;
          font-weight: 600;
          font-family: $font-family-lineseed-bold;
          padding: 10px 0;
          position: relative;

          &:hover {
            color: $cl-black;

            &::after {
              display: block;
            }
          }
        }
      }

      &:hover {
        & > ul {
          visibility: visible;
          opacity: 1;
          transform: translateY(-20px);
        }
      }
    }

    .nav-link {
      color: $cl-black;
      font-family: $font-family-lineseed-extra-bold;
      font-size: $font-size-medium + 1;
      padding: 0 22px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: calc(50% + 12px);
        width: 0;
        left: 0;
        margin-left: 20px;
        height: 3px;
        background: $cl-black;
        transition: all .3s ease;
      }
    }

    .nav-item.active {
      .nav-link {
        &::after {
          width: calc(100% - 40px);
        }
      }
    }

    .nav-item {
      &:not(:first-child) {
        &:hover {
          .nav-link {
            &::after {
              width: calc(100% - 40px);
            }
          }
        }
      }

      &.out-link {
        background: url("../images/ic-outlink.svg") no-repeat;
        background-position: 95% 40%;
      }
    }
  }

  &__language {
    .nav-link {
      color: $cl-black;
      font-size: $font-size-base;
      padding: 0;
      font-weight: $font-weight-bold;
      font-family: $font-family-lineseed-bold;
    }

    .nav-item {
      .nav-link {
        color: rgba($cl-black, .3);
        transition: all .1s ease;

        &:hover {
          color: $cl-black;
        }
      }

      &.active {
        .nav-link {
          color: $cl-black;
        }
      }

      &:first-child {
        .nav-link {
          padding-right: 12px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 9px;
            background: rgba($cl-black, .3);
          }
        }
      }

      &:last-child {
        .nav-link {
          padding-left: 8px;
        }
      }
    }

    @include respond-to('desktop-large') {
      position: absolute;
      width: calc(100% - 40px);
      bottom: 37px;
      left: 20px;
      padding-top: 20px;
      border-top: 1px solid $cl-gray8;

      .nav-item {
        &:first-child {
          padding-left: 24px;

          .nav-link {
            &::before {
              position: absolute;
              content: '';
              left: -24px;
              top: 0;
              width: 20px;
              height: 20px;
              background: url('../images/ic-language.svg') center no-repeat;
            }

            &::after {
              width: 2px;
              height: 2px;
              border-radius: 50%;
              background: $cl-black;
            }
          }
        }
      }
    }

    @include respond-to('mobile') {
      padding-top: 18px;
      padding-bottom: 33px;
      position: absolute;
      margin-top: 10px;
      bottom: 0;
      z-index: 3;
      background: #fff;
      width: calc(100% - 32px);
      left: 16px;
    }

    @include landscapeMode {
      padding-top: 18px;
      padding-bottom: 33px;
      padding-left: 16px;
      position: fixed;
      left: 16px;
      margin-top: 10px;
      bottom: 0;
      z-index: 3;
      background: #fff;
      width: calc(100% - 32px);
    }
  }

  &--homepage {
    background: transparent;

    .logo-black {
      display: none;
    }

    .logo-white {
      display: inline-block;
    }

    .dc-menu__left {
      .nav-item {
        &.out-link {
          background: url("../images/ic-outlink-white.svg") no-repeat;
          background-position: 95% 40%;
        }

        > ul {
          a:hover {
            color: $cl-white;
          }
        }
      }

      .nav-link {
        color: $cl-white;

        &::after {
          background: $cl-white;
        }
      }
    }

    .dc-menu__language {
      .nav-link {
        color: $cl-white;
      }

      .nav-item {
        .nav-link {
          color: rgba($cl-white, .3);
          transition: all .1s ease;

          &:hover {
            color: $cl-white;
          }
        }

        &.active {
          .nav-link {
            color: $cl-white;
          }
        }

        &:first-child {
          .nav-link {
            &::after {
              background: rgba($cl-white, .3);
            }
          }
        }
      }
    }
  }
}

.dc-menu-mobile {
  position: relative;
  z-index: 1040;
  border-bottom: 1px solid rgba($cl-black, .1);

  .navbar-brand {
    color: $cl-black;
  }

  &__navbar {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 90px;
  }

  &--home {
    .button-black {
      display: none;
    }

    .navbar-brand {
      color: $cl-white;
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .navbar-brand {
    font-family: $font-family-lineseed-extra-bold;
    transition: all .2s ease;
    font-size: $font-size-large;
  }

  &__navbar {
    .nav-item {
      position: relative;

      &:first-child {
        display: none;
      }

      > ul {
        padding: 10px;

        @include respond-to('mobile') {
          padding: 16px 10px;
        }

        @include landscapeMode {
          padding: 0 10px;
        }

        li {
          line-height: 32px;
        }

        a {
          color: $cl-black;
          font-family: $font-family-sf-pro;
          padding: 10px 0;
          position: relative;
          font-size: 16px;
          line-height: 24px;

          &:hover {
            color: $cl-black2;
          }
        }
      }

      .nav-link {
        font-size: $font-size-xlarge;
        font-weight: $font-weight-bold;
        padding: 8px 0;
        display: inline-block;
        position: relative;
        line-height: 35px;

        @include respond-to('mobile') {
          padding: 4px 0;
          font-family: $font-family-sf-pro-bold;
        }

        @include landscapeMode {
          padding: 0;
          font-family: $font-family-sf-pro-bold;
        }

        @include landcapeModeIphoneSmall {
          padding: 0;
        }

        @include landcapeModeIphone {
          padding: 0;
        }
      }

      &.active {
        .nav-link {
          &::after {
            position: absolute;
            content: '';
            top: 20px;
            left: calc(100% + 16px);
            background: $cl-green;
            width: 60px;
            height: 4px;
          }
        }
      }
    }
  }

  &__collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100vw;
    padding-right: 1rem;
    padding-left: 20px;
    visibility: hidden;
    transition: all .25s ease;
    background: $cl-white;
    padding-top: 60px;
    overflow-y: hidden;
    z-index: 100;

    @include respond-to('mobile') {
      padding-top: 50px;
    }

    &.open {
      right: 0;
      visibility: visible;
    }
  }
}
