// Style for FAQ Page

.dc-faq {
  padding: 70px 0 160px;

  @include respond-to('desktop') {
    padding: 35px 0 55px;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $cl-black;
  }

  &__box {
    letter-spacing: -0.46px;

    @include respond-to('mobile') {
      border-bottom: 0;
    }

    &:not(:first-child) {
      border-top: 1px solid #ddd;
      padding-top: 32px;

      @include respond-to('desktop') {
        display: none;
      }
    }

    &:first-child {
      @include respond-to('desktop') {
        padding-top: 0;
      }

      @include landscapeMode {
        padding-top: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__item {
    margin-bottom: 24px;
    border: 0;

    @include respond-to('mobile') {
      border-bottom: 1px solid #ddd;
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 13px;
    font-size: $font-size-large;
    font-weight: $font-weight-medium;
    font-family: $font-family-sf-pro-medium;
    color: $cl-black;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    transition: all .2s ease;
    letter-spacing: -0.46px;

    @include respond-to('mobile') {
      font-size: $font-size-medium;
      padding-bottom: 24px;
    }

    span {
      width: calc(100% - 25px);
      display: inline-block;
    }

    &::after {
      position: absolute;
      top: 10px;
      right: 0;
      width: 20px;
      height: 12px;
      display: block;
      content: "";
      background-image: url('../images/ic-faq-arrow-up.svg');
      background-repeat: no-repeat;
      transition: all .3s ease;

      @include respond-to('desktop') {
        top: 9px;
      }

      @include landscapeMode {
        top: 9px;
      }
    }

    &:not(.collapsed) {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &__text {
    font-size: $font-size-medium - 1;
    font-weight: 300;
    line-height: 1.73;
    letter-spacing: -0.4px;
    color: $cl-dark;
    padding-right: 60px;
    text-align: left;

    @include respond-to('desktop') {
      padding-right: 35px;
    }
  }

  &__link {
    display: inline-block;
    margin-top: 24px;
    font-size: $font-size-medium;
    font-weight: $font-weight-bold;
    line-height: 2.19;
    letter-spacing: -0.4px;
    color: $cl-black;

    @include respond-to('mobile') {
      margin-top: 0;
    }
  }

  &__select {
    width: 140px;
    position: relative;

    @include respond-to('tablet') {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    @include landcapeModeTable {
      margin-bottom: 32px;
    }

    @include landscapeMode {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &::after {
      position: absolute;
      content: '';
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background-color: $cl-white;
      background-image: url('../images/ic-triangle.svg');
      background-repeat: no-repeat;
      background-position: center;
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      font-weight: $font-weight-bold;
      letter-spacing: -0.33px;
      font-size: $font-size-base - 1;
      color: $cl-black;
      border: 1px solid #e1e1e1;
    }
  }
}
