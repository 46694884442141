// Import a custom font below
@font-face {
  font-family: 'LINESeed';
  src: url('../fonts/LINESeed_W_Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LINESeedBold';
  src: url('../fonts/LINESeed_W_Bd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'LINESeedExtraBold';
  src: url('../fonts/LINESeed_W_XBd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('../fonts/SFProText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText Medium';
  src: url('../fonts/SFProText-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText Bold';
  src: url('../fonts/SFProText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// Set font variables
$font-family-base: -apple-system, BlinkMacSystemFont, sans-serif;
$font-family-lineseed: 'LINESeed', $font-family-base;
$font-family-lineseed-extra-bold: 'LINESeedExtraBold', $font-family-base;
$font-family-lineseed-bold: 'LINESeedBold', $font-family-base;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-sf-pro: 'SFProText', sans-serif;
$font-family-sf-pro-medium: 'SFProText Medium', sans-serif;
$font-family-sf-pro-bold: 'SFProText Bold', sans-serif;

// Font size
$font-size-base: 14px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-xlarge: 24px;
$font-size-xxlarge: 32px;

// Font weight
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-extrabold: 700;

html {
  font-size: 16px;

  @include respond-to('mobile') {
    font-size: 14px;
  }
}

body {
  font-family: $font-family-sf-pro;
  font-size: $font-size-base;
  color: $cl-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
