// Style for News Page

.dc-page-news {
  padding: 70px 0;

  @include respond-to('mobile') {
    padding: 36px 0;
  }

  @include landscapeMode {
    padding: 36px 0;
  }

  .dc-news__desc {
    @include text-truncate($line: 4);

    @include respond-to('mobile') {
      @include text-truncate($line: 5);
    }

    @include landscapeMode {
      @include text-truncate($line: 5);
    }
  }

  [class*='col-'] {
    &:first-child {
      .dc-news__item {
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 80px;

        @include respond-to('desktop-large') {
          display: block;
          margin: 40px 0;
        }

        @include respond-to('mobile') {
          margin-top: 0;
        }

        @include landscapeMode {
          margin-top: 0;
        }

        &::before,
        &::after {
          content: '';
          position: absolute;
          right: 0;
          width: 350px;

          @include respond-to('desktop-large') {
            display: none;
          }
        }

        &::before {
          height: 4px;
          background: $cl-black;
          top: 0;
        }

        &::after {
          height: 1px;
          background: rgba($cl-black, .1);
          bottom: 0;
        }
      }

      .dc-news__desc {
        @include text-truncate($line: 6);

        @include respond-to('mobile') {
          @include text-truncate($line: 5);
        }

        @include landscapeMode {
          @include text-truncate($line: 5);
        }
      }

      .dc-news__img {
        min-width: 790px;
        height: 418px;
        margin-right: 30px;

        @include respond-to('desktop-large') {
          min-width: unset;
          margin-right: 0;
        }

        @include respond-to('mobile') {
          width: 100%;
          height: 160px;
        }

        @include landscapeMode {
          width: 100%;
          height: 160px;
        }
      }
    }

    &:not(:first-child) {
      .dc-news__item {
        margin-bottom: 66px;

        @include respond-to('mobile') {
          margin-bottom: 40px;
        }

        @include landscapeMode {
          margin-bottom: 40px;
        }
      }

      .dc-news__img {
        height: 250px;

        @include respond-to('mobile') {
          height: 160px;
        }

        @include landscapeMode {
          height: 160px;
        }
      }
    }
  }

  &__viewmore {
    margin: 21px 0 0;

    @include respond-to('mobile') {
      margin: 0 0 10px;

      img {
        max-width: 42px;
      }
    }

    @include landscapeMode {
      margin: 0 0 10px;

      img {
        max-width: 42px;
      }
    }
  }
}
