// Style for Apply Page

.dc-apply {
  padding: 32px 0 0;

  &__wrap {
    width: 800px;
  }

  &__form {
    &-item {
      padding: 24px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $cl-gray3;
      }
    }

    h3 {
      font-size: $font-size-xxlarge;
      margin-bottom: 24px;

      .required {
        color: $cl-green;
      }
    }

    p {
      font-size: $font-size-medium;
      line-height: 24px;
      margin-bottom: 0;
      color: $cl-dark;
    }
  }

  .form-control {
    color: $cl-black2;
  }

  .col-form-label,
  .form-check-label {
    font-size: $font-size-medium;
    color: $cl-dark;
  }

  &__review {
    table {
      th {
        width: 250px;
      }
    }
  }
}
