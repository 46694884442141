// Style for Philosophy page

.dc-policy {
  padding: 70px 0;
  font-family: $font-family-sf-pro;

  @include respond-to('mobile') {
    padding: 35px 0;
  }

  @include landscapeMode {
    padding: 35px 0;
  }

  &__content {
    ul,
    p {
      color: $cl-dark;
      font-size: 16px;
    }
  }

  &__item {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px solid #ddd;

    @include respond-to('mobile') {
      margin-top: 40px;
      padding-top: 40px;
    }

    @include landscapeMode {
      margin-top: 40px;
      padding-top: 40px;
    }

    h4 {
      font-size: 20px;
      color: #000;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
}
