// Style for Philosophy page

.dc-philosophy {
  padding: 70px 0 100px;

  @include respond-to('mobile') {
    padding: 35px 0;
  }

  @include landscapeMode {
    padding: 35px 0;
  }

  &__tabs {
    margin-top: 60px;

    @include respond-to('mobile') {
      margin-top: 0;
    }

    .nav-tabs {
      border-bottom: 0;

      .nav-item {
        text-align: center;

        @include respond-to('mobile') {
          &:not(:first-child) {
            margin-top: 10px;
          }
        }

        @include landscapeMode {
          &:not(:first-child) {
            margin-top: 10px;
          }
        }
      }

      .nav-item {
        .nav-link {
          @include landscapeMode {
            display: inline-block;
          }
        }
      }

      .nav-link {
        padding: 15px 0;
        font-weight: 600;
        font-size: 18px;
        border: 0;
        border-bottom: 2px solid transparent;

        @include respond-to('mobile') {
          display: inline-block;
          padding: 5px 0;
        }

        &.active {
          color: $cl-green;
          border-bottom: 2px solid $cl-green;
        }
      }
    }

    .tab-content {
      margin-top: 60px;

      @include respond-to('mobile') {
        margin-top: 35px;
      }

      @include landscapeMode {
        margin-top: 35px;
      }
    }
  }

  &__content {
    max-width: 1000px;
    margin: 0 auto;
    color: $cl-black2;

    @include respond-to('desktop') {
      max-width: 100%;
    }
  }

  &__mission {
    &-intro {

      h3 {
        font-size: 22px;
        margin-bottom: 24px;
      }

      p {
        font-size: 17px;
        margin-bottom: 0;
        line-height: 30px;

        @include respond-to('mobile') {
          font-size: 16px;
        }

        @include landscapeMode {
          font-size: 16px;
        }
      }
    }

    &-text,
    &-vision {
      margin-top: 80px;

      @include respond-to('mobile') {
        margin-top: 35px;
      }

      @include landscapeMode {
        margin-top: 35px;
      }

      h2 {
        font-size: 40px;
        text-align: center;

        @include respond-to('mobile') {
          font-size: $font-size-xxlarge;
        }

        @include landscapeMode {
          font-size: $font-size-xxlarge;
        }
      }

      h4 {
        color: $cl-green;
        font-size: $font-size-large;
        text-align: center;
      }

      h5 {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        font-family: $font-family-lineseed-extra-bold;
        margin-bottom: 32px;
      }

      p {
        font-size: 17px;
        text-align: left;
      }
    }
  }

  &__item,
  &__style,
  &__code {
    margin-top: 60px;

    @include respond-to('mobile') {
      margin-top: 35px;
    }

    @include landscapeMode {
      margin-top: 35px;
    }

    h3 {
      font-size: $font-size-xxlarge;
      font-weight: 700;
    }

    h4 {
      font-size: $font-size-large;
      color: $cl-green;
      margin-bottom: 32px;
    }

    p {
      font-size: 17px;
      line-height: 30px;
      text-align: left;
    }

    a {
      font-size: 17px;
      color: $cl-dark;
      text-decoration: underline;
    }
  }
}
