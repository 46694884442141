// Style for footer

.dc-footer {
  color: $cl-black2;

  .container {
    border-top: 1px solid $cl-gray;
    padding: 38px 0;

    @include respond-to('mobile') {
      padding: 0 15px 46px;
    }

    @include landscapeMode {
      padding: 0 15px 46px;
    }
  }

  &__left {
    h4 {
      font-weight: 700;
      margin: 10px 0;
    }

     p { max-width: 445px; }
  }

  &__right {
    position: relative;
  }

  &__link {
    padding-top: 20px;
    margin-top: -20px;
    padding-right: 47px;

    @include respond-to('mobile') {
      padding-right: 0;
    }

    @include landscapeMode {
      padding-right: 0;
    }

    span {
      font-family: $font-family-sf-pro;
    }

    &:hover .dc-footer__social-list {
      display: block;
      opacity: 1;

      li {
        margin-bottom: 12px;
        display: block;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__social {
    position: relative;
    padding-left: 0;
    margin-top: -16px;

    @include respond-to('mobile') {
      padding-left: 0;
    }

    @include landscapeMode {
      padding-left: 0;
    }

    .list-inline-item {
      padding-top: 16px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    &-list {
      font-family: $font-family-sf-pro;
      position: absolute;
      padding: 20px 16px;
      width: 204px;
      min-height: 90px;
      background: $cl-white;
      font-size: $font-size-base - 1;
      border: 1px solid $cl-gray2;
      left: -41px;
      bottom: 42px;
      z-index: 3;
      display: none;
      opacity: 0;
      transition: all .3s ease;

      @include respond-to('mobile') {
        left: 0;
      }

      @include landscapeMode {
        left: 0;
      }

      li {
        a {
          color: $cl-brown-gray;

          &:hover {
            color: $cl-black2;
          }
        }
      }

      &::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid $cl-white;
        background: $cl-white;
        top: calc(100% - 11px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
      }

      &::after {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        border: 1px solid $cl-gray;
        background: $cl-white;
        top: calc(100% - 6px);
        left: 50%;
        transform: translateX(-50%) rotate(-45deg);
      }

      &::before,
      &::after {
        @include respond-to('mobile') {
          left: 14px;
        }

        @include landscapeMode {
          left: 14px;
        }

        @media screen
        and (max-width: 640px)
        and (orientation: landscape) {
          left: 14px;
        }
      }
    }

    &-facebook,
    &-linkin {
      &:hover .dc-footer__social-list {
        display: block;
        opacity: 1;

        li {
          &:last-child {
            display: block;
            margin-top: 12px;
          }
        }
      }
    }

    img {
      cursor: pointer;
      max-width: 27px;
    }

    &-facebook {
      .dc-footer__social-list {
        left: -89px;

        @include respond-to('mobile') {
          left: 0;
        }

        @include landscapeMode {
          left: 0;
        }
      }
    }

    &-linkin {
      .dc-footer__social-list {
        left: -49px;

        @include respond-to('mobile') {
          left: 0;
        }

        @include landscapeMode {
          left: 0;
        }

        &::before,
        &::after {
          @include respond-to('mobile') {
            left: 52px;
          }

          @include landscapeMode {
            left: 52px;
          }

          @media screen
          and (max-width: 640px)
          and (orientation: landscape) {
            left: 52px;
          }
        }
      }
    }
  }

  @include respond-to('mobile') {
    font-size: $font-size-base - 1;

    &__top {
      padding: 18px 30px;
      width: calc(100vw + 30px);
      margin-left: -30px;
      border-bottom: 1px solid $cl-gray;
    }

    &__social {
      padding: 0;
      margin: 0;
      margin-left: -3px;

      .list-inline-item {
        &:not(:last-child) {
          padding-top: 0;
        }
      }
    }

    &__links {
      margin: 24px 0 16px;

      .list-inline-item {
        position: relative;

        &:not(:last-child) {
          padding-right: 32px;
          margin-right: 0;

          &::after {
            position: absolute;
            content: '';
            top: 4px;
            right: 15px;
            background: $cl-gray;
            width: 1px;
            height: 12px;
          }
        }
      }
    }
  }

  @include landscapeMode {
    &__links {
      .list-inline-item {
        position: relative;

        &:not(:last-child) {
          padding-right: 32px;
          margin-right: 0;

          &::after {
            position: absolute;
            content: '';
            top: 4px;
            right: 15px;
            background: $cl-gray;
            width: 1px;
            height: 12px;
          }
        }
      }
    }
  }
}