// Style for Career Page

.dc-career {
  padding: 70px 0 100px;

  @include respond-to('mobile') {
    padding: 35px 0 50px;
  }

  @include landcapeModeIphoneX {
    padding: 35px 0 50px;
  }

  @include landcapeModeIphone {
    padding: 35px 0 50px;
  }

  &__item {
    span {
      color: $cl-green;
    }
  }

  .dc-newjob__item {
    padding-right: 100px;

    @include respond-to('mobile') {
      padding-right: 30px;
    }
  }

  .dc-newjob__text {
    @include text-truncate($line: 4);
  }

  .dc-newjob__meta {
    @include respond-to('mobile') {
      margin-top: 16px;
    }

    @include landscapeMode {
      margin-top: 16px;
    }
  }

  &__top {
    align-items: flex-end;

    @include landcapeModeTable {
      margin-bottom: 36px;
    }

    @include respond-to('desktop') {
      margin-bottom: 36px;
    }

    @include respond-to('mobile') {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 36px;
    }

    @include landscapeMode {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 36px;
    }
  }

  &__viewmore {
    margin: 40px 0;

    .btn {
      padding: 0;
    }

    @include respond-to('mobile') {
      margin: 24px 0;

      img {
        max-width: 42px;
      }
    }
  }

  &__join {
    background: $cl-gray9;
    padding: 45px 100px;

    @include landcapeModeTable {
      padding: 24px;
    }

    @include respond-to('tablet') {
      padding: 24px;
    }

    @include respond-to('mobile') {
      padding: 24px;
      flex-direction: column;
    }

    &-header {
      font-size: $font-size-xxlarge;
      font-family: $font-family-sf-pro-bold;
      margin-bottom: 9px;
      font-weight: $font-weight-bold;
      line-height: 36px;

      @include respond-to('mobile') {
        font-size: $font-size-xlarge + 2;
      }
    }

    &-desc {
      font-size: $font-size-medium - 1;
      font-family: $font-family-sf-pro;
      margin-bottom: 0;
      max-width: 530px;
      line-height: 23px;
      letter-spacing: -0.15px;

      @include landcapeModeTable {
        max-width: 450px;
      }

      @include respond-to('tablet') {
        max-width: 450px;
      }
    }

    &-btn {
      font-size: $font-size-xlarge;
      font-weight: $font-weight-bold;
      font-family: $font-family-sf-pro-bold;
      padding: 0;
    }
  }

  &__center {
    .list-inline-item {
      &:not(:last-child) {
        margin-right: 24px;
      }

      a {
        font-weight: $font-weight-bold;
        font-size: $font-size-large - 2;
        color: rgba($cl-black, .25);
        position: relative;
        padding: 0;
        font-family: $font-family-lineseed-bold;
      }

      &.active {
        a {
          color: $cl-black;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            background: $cl-black;
            width: 100%;
            height: 2px;
          }
        }
      }
    }
  }

  [class*='col-'] {
    .dc-newjob__item {
      height: 350px;
      margin-top: -1px;

      @include respond-to('mobile') {
        height: 100%;
      }

      @include landscapeMode {
        height: 100%;
        padding: 26px 30px 28px;
      }

      ul {
        padding-left: 0;
        list-style: none;
      }

      ul, p {
        font-size: $font-size-medium;
      }
    }

    .dc-newjob__item {
      &.disabled {
        opacity: .3;

        &:hover {
          background: transparent;
          color: $cl-dark;

          .dc-newjob__header {
            color: $cl-black;
          }
        }
      }
    }

    &:first-child {
      .dc-newjob__item {
        font-weight: $font-weight-bold;
        font-family: $font-family-lineseed-bold;
        color: $cl-black;

        .dc-newjob__header {
          display: block;
        }

        &:hover {
          background: transparent;

          .dc-newjob__header {
            color: $cl-black;
          }
        }
      }

      .dc-newjob__header {
        font-size: 50px;
        line-height: 56px;

        @include respond-to('mobile') {
          font-size: $font-size-xxlarge + 4;
          line-height: 39px;
        }

        @include landscapeMode {
          font-size: $font-size-xxlarge + 4;
          line-height: 39px;
        }

        & + span {
          font-size: $font-size-medium;
        }
      }

      .dc-newjob__meta {
        font-size: $font-size-large - 2;

        .list-inline-item {
          &::after {
            display: none;
          }

          &.disabled-filter {
            color: rgba($cl-black, .25);

            span {
              color: rgba($cl-black, .25);
            }
          }
        }
      }
    }

    &:not(:first-child) {
      .dc-newjob__item {
        @include respond-to('mobile') {
          border-top: 0;
        }

        @include landscapeMode {
          border-top: 0;
        }
      }
    }
  }

  &__modal {
    .form-control {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $cl-black;
      padding-left: 0;
      padding-right: 0;


      &:focus {
        box-shadow: none;
      }
    }

    .list-radio {
      transform: translateY(4px);
      position: relative;
      z-index: 1;

      @include respond-to('mobile') {
        transform: translateY(2px);
      }

      @include landscapeMode {
        transform: translateY(0px);
      }
    }

    .col-form-label {
      margin-top: 16px;
      transform: translateY(5px);

      @include respond-to('mobile') {
        transform: translateY(0);
      }

      @include landscapeMode {
        transform: translateY(1px);
        margin-top: 20px;
      }
    }

    &-title {
      font-size: $font-size-xlarge;
      color: #222;
      font-family: $font-family-sf-pro-bold;
    }

    &-desc {
      color: #a1a1a1;
      line-height: 18px;
      font-weight: $font-weight-medium;
      margin-bottom: 36px;
    }

    &-body {
      padding: 0 30px;
      font-family: $font-family-sf-pro;
    }

    &-form {
      [type="file"] {
        height: 0;
        overflow: hidden;
        width: 0;
        display: none;
      }
    }

    &-btn {
      font-size: $font-size-large;
      width: calc(100% + 60px);
      margin-left: -30px;
      border-radius: 0;

      &:hover {
        background-color: $cl-green;
        border-color: $cl-green;
      }
    }

    label.input-cv {
      border-bottom: 1px solid $cl-black;
      cursor: pointer;
    }
  }
}
