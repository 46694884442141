// Style for Career Detail Page

.dc-career-detail {
  padding: 70px 0 100px;

  @include respond-to('desktop-large') {
    padding: 50px 0;
  }

  @include respond-to('mobile') {
    padding: 35px 0;
  }

  @include landscapeMode {
    padding: 35px 0;
  }

  .dc-page-title {
    margin-left: -5px;
  }

  &__top {
    border-bottom: 1px solid $cl-gray3;
    padding-bottom: 47px;
    margin-bottom: 6px;

    @include respond-to('mobile') {
      padding-bottom: 24px;
    }

    @include landscapeMode {
      padding-bottom: 24px;
    }
  }

  &__name {
    h3 {
      font-size: $font-size-xxlarge + 2;
      font-weight: $font-weight-bold;
      line-height: 40px;
      font-family: $font-family-sf-pro-bold;
      @include text-truncate($line: 2);

      @include respond-to('mobile') {
        font-size: $font-size-xlarge + 1;
        line-height: 32px;
        margin-bottom: 0;
      }

      @include landscapeMode {
        font-size: $font-size-xlarge + 1;
        line-height: 32px;
        margin-bottom: 0;
      }
    }

    ul {
      font-size: $font-size-base;
      color: $cl-dark;

      @include respond-to('desktop') {
        padding: 20px 0;
      }

      .list-inline-item {
        position: relative;
        font-family: $font-family-sf-pro;

        &:not(:last-child) {
          margin-right: 21px;

          &::after {
            position: absolute;
            content: '';
            right: -13px;
            top: 5px;
            background: #d8d8d8;
            width: 1px;
            height: 12px;
          }
        }
      }
    }
  }

  &__social {
    min-width: 135px;

    img {
      max-width: 38px;
    }
  }

  &__content {
    margin-top: 50px;
    font-family: $font-family-sf-pro;

    @include respond-to('mobile') {
      margin-top: 40px;
    }

    @include landcapeModeIphone {
      margin-top: 40px;
    }

    @include landcapeModeIphoneX {
      margin-top: 40px;
    }

    h4 {
      margin-bottom: 15px;
      font-weight: $font-weight-bold;
      font-size: $font-size-large;
      font-family: $font-family-sf-pro-bold;

      @include respond-to('mobile') {
        margin-bottom: 12px;
      }

      @include landcapeModeIphone {
        margin-bottom: 12px;
      }

      @include landcapeModeIphoneX {
        margin-bottom: 12px;
      }
    }

    ul {
      color: $cl-dark;
      font-size: $font-size-medium;
      line-height: 30px;

      li {
        position: relative;
        padding-left: 12px;
        list-style: none;

        &::after {
          position: absolute;
          content: '';
          left: 0;
          top: 12px;
          background: $cl-gray10;
          width: 5px;
          height: 5px;
          border-radius: 50%;
        }

        @include respond-to('mobile') {
          letter-spacing: -0.4px;
        }

        @include landcapeModeIphone {
          letter-spacing: -0.4px;
        }

        @include landcapeModeIphoneX {
          letter-spacing: -0.4px;
        }
      }
    }

    p {
      color: $cl-dark;
      font-size: $font-size-medium;
      line-height: 30px;
      line-height: 26px;
      letter-spacing: -0.16px;

      @include respond-to('mobile') {
        letter-spacing: -0.4px;
      }

      @include landcapeModeIphone {
        letter-spacing: -0.4px;
      }

      @include landcapeModeIphoneX {
        letter-spacing: -0.4px;
      }
    }
  }

  &__backlist {
    font-weight: $font-weight-bold;
    font-family: $font-family-sf-pro;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 42px;
    margin-top: 16px;

    @include respond-to('mobile') {
      margin-bottom: 0;
    }

    @media only screen and ( orientation: landscape ) and (max-width : 812px) {
      margin-bottom: 0;
    }

    @media only screen and ( orientation: landscape ) and (max-width : 736px) {
      margin-bottom: 0;
    }

    @media only screen and ( orientation: landscape ) and (max-width : 667px) {
      margin-bottom: 0;
    }
  }

  &__apply {
    border-top: 1px solid $cl-gray3;
    padding: 15px 0 50px;
    text-align: center;
    margin-top: 66px;
    background: #fff;
    position: fixed;
    left: 0;
    background: #fff;
    bottom: 0;
    width: 100%;
    z-index: 1;

    &.remove-fixed {
      position: relative;

      @include respond-to('mobile') {
        position: fixed;
      }

      @include landscapeMode {
        position: fixed;
      }
    }

    @include respond-to('desktop-large') {
      padding: 15px 0 30px;
    }

    @include respond-to('mobile') {
      padding: 0;
      margin-top: 0;
    }

    @include landscapeMode {
      padding: 0;
      margin-top: 0;
    }

    &-btn {
      font-family: $font-family-sf-pro-bold;
      width: 580px;
      height: 80px;
      line-height: 60px;
      font-weight: $font-weight-bold;
      margin: 0 auto;
      font-size: $font-size-large;
    }

    a,
    .btn {
      @include respond-to('desktop-large') {
        width: 50vw;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        font-size: $font-size-large - 2;
        font-family: $font-family-sf-pro-bold;
      }

      @include respond-to('mobile') {
        line-height: 34px;
        letter-spacing: 0;
      }

      @include landscapeMode {
        line-height: 34px;
        letter-spacing: 0;
      }
    }

    .btn-secondary {
      background-color: #d4d4d4;

      &:hover {
        border-color: #d4d4d4;
      }

      &:active {
        background-color: #d4d4d4 !important;
        border-color: #d4d4d4 !important;
      }
    }

    &-mobile {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }
  }

  .dc-career__join {
    display: none !important;

    &.show {
      display: flex !important;

      @include respond-to('mobile') {
        display: none !important;
      }

      @include landscapeMode {
        display: none !important;
      }
    }

    @include respond-to('mobile') {
      display: none !important;
    }

    @include landscapeMode {
      display: none !important;
    }
  }
}
