// Style for Home Page

.dc-hero {
  height: 840px;
  margin-bottom: 84px;
  position: relative;
  z-index: 3;
  margin-top: -88px;

  @include respond-to('desktop-large') {
    height: 600px;
    margin-top: -63px;
  }

  @include respond-to('mobile') {
    height: 562px;
    margin-bottom: 45px;
  }

  @include landscapeMode {
    height: 400px;
    margin-bottom: 45px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba($cl-black, .5);
    z-index: 2;
  }

  &__video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }

  &__content {
    position: absolute;
    top: 50%;
    margin-top: 23px;
    transform: translateY(-50%);
    z-index: 3;

    @include respond-to('desktop-large') {
      margin: 0;
    }

    @include respond-to('mobile') {
      padding: 0 40px;
    }

    @include landcapeModeIphone {
      padding: 0;
    }

    @include landcapeModeIphoneX {
      padding: 0;
      padding-top: 50px;
    }

    h1 {
      font-size: 90px;
      line-height: 110px;
      margin-bottom: 40px;
      font-family: $font-family-lineseed-extra-bold;

      @include respond-to('desktop-large') {
        font-size: $font-size-xxlarge * 2;
        line-height: 70px;
        margin-bottom: 30px;
      }

      @include respond-to('mobile') {
        font-size: $font-size-xxlarge + 6;
        line-height: 42px;
        margin-bottom: 20px;
      }

      @include landscapeMode {
        font-size: $font-size-xxlarge + 6;
        line-height: 42px;
        margin-bottom: 20px;
      }
    }

    p {
      font-size: $font-size-large;
      font-family: $font-family-sf-pro;
      margin-bottom: 40px;
      line-height: 30px;

      @include respond-to('desktop-large') {
        margin-bottom: 30px;
      }

      @include respond-to('mobile') {
        font-size: $font-size-medium;
        line-height: 24px;
        margin-bottom: 0;
      }

      @include landscapeMode {
        font-size: $font-size-medium;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
  }

  &__center {
    @include respond-to('mobile') {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
    }

    @media only screen and ( orientation: landscape ) and (max-width : 812px) {
      position: absolute;
      left: -60px;
      top: calc(100% + 39px);
      width: 100vw;
      z-index: 2;
    }

    @media only screen and ( orientation: landscape ) and (max-width : 736px) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      top: initial;
    }

    @media only screen and ( orientation: landscape ) and (max-width : 667px) {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      top: initial;
    }

    .list-inline-item {
      position: relative;
      color: $cl-white;
      transition: all .1s ease;

      @include respond-to('mobile') {
        margin-left: -1px;
      }

      @include landscapeMode {
        margin-left: -1px;
      }

      &:hover {
        background: $cl-white;

        > a {
          color: $cl-black;
        }

        .dc-arrow {
          border-top: 1px solid $cl-black;
          border-left: 1px solid $cl-black;
          cursor: pointer;
        }
      }

      &:not(:last-child) {
        margin-right: 0;
      }

      &:last-child {
        margin-left: -5px;

        @include respond-to('mobile') {
          margin-left: -1px;
        }

        @media only screen and ( orientation: landscape ) and (max-width : 812px) {
          margin-left: -5px;

          > a {
            border-right: 0;
          }
        }

        @media only screen and ( orientation: landscape ) and (max-width : 667px) {
          margin-left: -1px;
        }

        @media only screen and ( orientation: landscape ) and (max-width : 736px) {
          margin-left: -1px;
        }
      }

      > a {
        border: 1px solid $cl-white;
        width: 200px;
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        font-size: $font-size-large;
        display: inline-block;
        color: $cl-white;
        font-family: $font-family-sf-pro-bold;
        font-weight: 600;

        @include respond-to('mobile') {
          width: calc(50vw + 2px);
        }

        @include landscapeMode {
          width: calc(50vw + 2px);
        }
      }
    }
  }
}

.dc-newjob {
  margin-bottom: 90px;

  @include respond-to('mobile') {
    margin-bottom: 50px;
  }

  @include landscapeMode {
    margin-bottom: 50px;
  }

  .owl-theme .owl-dots .owl-dot {
    span {
      @include respond-to('mobile') {
        width: 6px;
        height: 6px;
        margin: 4px;
        background: $cl-gray;
      }

      @include landscapeMode {
        width: 6px;
        height: 6px;
        margin: 4px;
        background: $cl-gray;
      }
    }

    &.active span {
      background: $cl-green;
    }
  }

  &__item {
    font-family: $font-family-sf-pro;
    border: 1px solid $cl-gray3;
    padding: 46px 30px 38px;
    height: 420px;
    display: flex;
    flex-direction: column;
    margin-left: -1px;
    transition: all .3s ease;
    color: $cl-dark;

    @include respond-to('mobile') {
      height: 315px;
      margin: 0;
      padding: 26px 30px 28px;
    }

    @include landscapeMode {
      height: 315px;
      margin: 0;
      padding: 26px 30px 28px;
    }

    &:hover {
      color: $cl-white;
      background: $cl-green;

      .dc-newjob__header {
        color: $cl-white;
      }

      .dc-newjob__meta .list-inline-item:first-child {
        &::after {
          background: $cl-white;
        }
      }
    }
  }

  &__header {
    font-size: $font-size-xlarge + 2;
    font-weight: $font-weight-bold;
    font-family: $font-family-sf-pro-bold;
    margin-bottom: 16px;
    line-height: 30px;
    color: $cl-black;
    @include text-truncate($line: 2);
  }

  ul {
    padding-left: 0;
    list-style: none;
    font-size: $font-size-medium;
  }

  &__text,
  p {
    font-size: $font-size-medium;
    line-height: 24px;
    letter-spacing: -0.16px;
    @include text-truncate($line: 6);

    @include respond-to('mobile') {
      max-height: 100px;
      line-height: 24px;
      letter-spacing: 0;
    }

    @include landscapeMode {
      max-height: 100px;
      line-height: 24px;
      letter-spacing: 0;
    }
  }

  &__meta {
    margin-top: auto;
    display: flex;
    align-items: center;

    .list-inline-item {
      &:not(:last-child) {
        margin-right: 20px;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 1px;
          height: 12px;
          background: $cl-gray4;
          top: 5px;
          right: -10px;
        }
      }
    }
  }
}

.dc-hire {
  background: $cl-gray5;
  padding: 76px 0 71px;
  margin-bottom: 82px;

  @include respond-to('mobile') {
    padding: 45px 0 60px;
    margin-bottom: 40px;
  }

  @include landscapeMode {
    padding: 45px 0 60px;
    margin-bottom: 40px;
  }

  &__content {
    h3 {
      font-size: $font-size-xlarge + 4;

      @include respond-to('desktop-large') {
        font-size: $font-size-large;
      }
    }
  }

  &__step {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;
    font-family: $font-family-sf-pro-bold;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      width: 80px;
      height: 10px;
      background: url("../images/ic-step-arrow.svg") center no-repeat;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;

      @include respond-to('desktop-large') {
        height: 50px;
        width: 10px;
        left: 50vw;
        top: calc(100% - 50px);
        background: url("../images/ic-step-arrow-mw.svg") center no-repeat;
      }

      @include landscapeMode {
        left: 53%;
      }
    }

    img {
      padding-right: 28px;
    }

    span {
      color: $cl-green;
      font-size: $font-size-medium - 1;
      font-weight: $font-weight-bold;
      line-height: 30px;
      font-family: $font-family-sf-pro-bold;
    }
  }

  [class*='col'] {
    .dc-hire__step {
      @include respond-to('desktop-large') {
        img {
            max-width: 110px;
        }
      }
    }

    &:first-child {
      .dc-hire__step {

        @include respond-to('desktop-large') {
          margin-left: -16px;
        }

        @include respond-to('mobile') {
          margin-left: -36px;
        }

        @include landscapeMode {
          margin-left: -36px;
        }

        &::after {
          left: 80%;

          @include respond-to('desktop-large') {
            top: calc(100% + 25px);
            left: 61vw;
            transform: translateX(-50%);
          }

          @include respond-to('mobile') {
            left: 60vw;
          }

          @media screen and (max-width: 375px) {
            left: 61vw;
          }

          @media only screen and ( orientation: landscape ) and (max-width : 812px) {
            left: 56%;
          }

          @media only screen and ( orientation: landscape ) and (max-width : 736px) {
            left: 57%;
          }

          @media only screen and ( orientation: landscape ) and (max-width : 667px) {
            left: 57%;
          }
        }
      }
    }

    &:nth-child(2) {
      .dc-hire__step {
        @include respond-to('desktop-large') {
          padding: 110px 0;
          position: relative;
        }
      }
    }

    &:last-child {
      .dc-hire__step {
        &::after {
          display: none;
        }
      }
    }
  }
}

.dc-home-blog {
  margin-bottom: 140px;

  @include respond-to('desktop-large') {
    margin-bottom: 34px;
  }

  [class*='col-'] {
    &:nth-child(2) {
      position: relative;

      @include respond-to('desktop-large') {
        padding: 0 15px;
      }

      &::before,
      &::after {
        position: absolute;
        content: '';
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 117px;
        background: $cl-gray6;

        @include respond-to('desktop-large') {
          display: none;
        }
      }

      &::before {
        left: 4px;
      }

      &::after {
        right: -4px;
      }
    }

    &:not(:last-child) {
      @include respond-to('desktop-large') {
        border-bottom: 1px solid $cl-gray6;
        padding-bottom: 34px;
      }
    }

    &:not(:first-child) {
      @include respond-to('desktop-large') {
        padding-top: 35px;
      }
    }
  }

  &__avatar {
    margin-right: 21px;

    img {
      min-width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }

  &__header {
    font-size: $font-size-large;
    line-height: 26px;
    font-family: $font-family-sf-pro-bold;

    a {
      &:hover {
        color: $cl-black;
      }
    }
  }

  &__meta,
  &__position {
    color: $cl-gray7;
    font-size: $font-size-medium;
    font-family: $font-family-sf-pro;
    line-height: 25px;
    letter-spacing: -0.12px;
  }

  &__meta {
    .list-inline-item:first-child {
      position: relative;
      margin-right: 10px;

      &::after {
        position: absolute;
        content: '';
        top: 50%;
        right: -8px;
        transform: translateY(-50%);
        background: $cl-gray7;
        width: 1px;
        height: 13px;
      }
    }
  }

  &__category {
    color: $cl-green;
    letter-spacing: -.16px;
    font-size: $font-size-medium;
    margin-bottom: 11px;
    display: block;
    font-family: $font-family-lineseed-bold;
  }
}

#hotjob-carousel .dc-newjob__text {
  @include respond-to('mobile') {
    @include text-truncate($line: 4);

    @include landscapeMode {
      @include text-truncate($line: 4);
    }
  }
}
