/* MEDIA QUERIES */
/* @include respond-to('mobile') {} */
/* @include browser('ie11') {} */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@font-face {
  font-family: 'LINESeed';
  src: url("../fonts/LINESeed_W_Rg.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'LINESeedBold';
  src: url("../fonts/LINESeed_W_Bd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'LINESeedExtraBold';
  src: url("../fonts/LINESeed_W_XBd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SFProText';
  src: url("../fonts/SFProText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SFProText Medium';
  src: url("../fonts/SFProText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'SFProText Bold';
  src: url("../fonts/SFProText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

html {
  font-size: 16px; }
  @media (max-width: 480px) {
    html {
      font-size: 14px; } }

body {
  font-family: "SFProText", sans-serif;
  font-size: 14px;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: #000;
  transition: all .2s ease; }

button:focus,
.btn:focus {
  outline: none;
  box-shadow: none; }

html,
body {
  height: auto; }
  @media (max-width: 969px) {
    html,
    body {
      overflow-x: hidden; } }
  html .disabled-scroll,
  body .disabled-scroll {
    position: fixed;
    height: 100vh;
    overflow-y: hidden; }

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important; }

body {
  width: 100%;
  min-height: 100%;
  margin: 0; }
  body.body-vn {
    font-family: "Roboto", sans-serif; }
    body.body-vn .dc-footer__left p {
      max-width: 500px; }
    @media (max-width: 1170px) {
      body.body-vn .dc-page-culture__office-header {
        margin-top: -20px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      body.body-vn .dc-page-culture__office-header {
        margin-top: -20px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      body.body-vn .dc-page-culture__office-header {
        margin-top: -20px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      body.body-vn .dc-page-culture__office-header {
        margin-top: -20px; } }
    body.body-vn .dc-page-culture__office-intro {
      margin-top: 0; }
      @media (max-width: 480px) {
        body.body-vn .dc-page-culture__office-intro {
          margin-top: 14px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        body.body-vn .dc-page-culture__office-intro {
          margin-top: 14px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        body.body-vn .dc-page-culture__office-intro {
          margin-top: 14px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        body.body-vn .dc-page-culture__office-intro {
          margin-top: 14px; } }
    body.body-vn .dc-career-detail__apply .btn-secondary {
      font-size: 16px; }
    body.body-vn .dc-page-title,
    body.body-vn .dc-hero__content h1,
    body.body-vn .dc-menu__left .nav-link,
    body.body-vn .dc-newjob__header,
    body.body-vn .dc-heading__title,
    body.body-vn .dc-about__header,
    body.body-vn .dc-page-culture__office-header,
    body.body-vn .dc-career__join-header,
    body.body-vn .dc-career-detail__apply-btn,
    body.body-vn .dc-career-detail__backlist,
    body.body-vn .dc-news-detail__backlist,
    body.body-vn .dc-hire__step,
    body.body-vn .dc-banner__center .list-inline-item > a,
    body.body-vn .dc-about__office-item-title,
    body.body-vn .dc-about__direction-item-title,
    body.body-vn .dc-page-culture__item-title,
    body.body-vn .dc-page-culture__office-item-title,
    body.body-vn .dc-career__join-btn,
    body.body-vn .dc-home-blog__header,
    body.body-vn .dc-career-detail__content h4,
    body.body-vn .dc-career-detail__name h3,
    body.body-vn .dc-menu-mobile__navbar .nav-item .nav-link,
    body.body-vn .dc-career__center .list-inline-item a,
    body.body-vn .dc-career [class*=col-]:first-child .dc-newjob__item,
    body.body-vn .dc-career-detail__apply a,
    body.body-vn .dc-career-detail__apply .btn {
      font-family: "Roboto", sans-serif;
      font-weight: 600; }
    body.body-vn .dc-heading__seemore,
    body.body-vn .dc-banner__content p,
    body.body-vn .dc-footer__link span,
    body.body-vn .dc-footer__social-list,
    body.body-vn .dc-newjob__item,
    body.body-vn .dc-home-blog__meta,
    body.body-vn .dc-home-blog__position,
    body.body-vn .dc-news__body,
    body.body-vn .dc-about__desc,
    body.body-vn .dc-about__office-item-desc,
    body.body-vn .dc-about__office-item-link,
    body.body-vn .dc-about__direction-item-info,
    body.body-vn .dc-career__join-desc,
    body.body-vn .dc-career-detail__name ul .list-inline-item,
    body.body-vn .dc-career-detail__content,
    body.body-vn .dc-career__modal-body,
    body.body-vn .dc-page-culture__office,
    body.body-vn .dc-policy,
    body.body-vn .dc-news-detail__name,
    body.body-vn .dc-news-detail__content,
    body.body-vn .dc-career__modal-title,
    body.body-vn .dc-hire__step span,
    body.body-vn .dc-faq__header,
    body.body-vn .dc-menu-mobile__navbar .nav-item > ul a {
      font-family: "Roboto", sans-serif; }
    @media (max-width: 480px) {
      body.body-vn .dc-banner__content h1 {
        line-height: 50px; } }
    @media (max-width: 480px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step {
        margin-left: -22px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step {
        margin-left: -22px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step {
        margin-left: -22px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step {
        margin-left: -22px; } }
    @media (max-width: 480px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step::after {
        left: calc(50% + 30px); } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step::after {
        left: calc(50% + 36px); } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step::after {
        left: calc(50% + 30px); } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      body.body-vn .dc-hire [class*=col]:first-child .dc-hire__step::after {
        left: calc(50% + 30px); } }
    body.body-vn .dc-career [class*='col-']:first-child .dc-newjob__header {
      letter-spacing: 1px; }
      @media (max-width: 480px) {
        body.body-vn .dc-career [class*='col-']:first-child .dc-newjob__header {
          line-height: 42px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        body.body-vn .dc-career [class*='col-']:first-child .dc-newjob__header {
          line-height: 42px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        body.body-vn .dc-career [class*='col-']:first-child .dc-newjob__header {
          line-height: 42px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        body.body-vn .dc-career [class*='col-']:first-child .dc-newjob__header {
          line-height: 42px; } }
    @media (max-width: 480px) {
      body.body-vn .dc-faq__select {
        width: 160px; } }
    @media (max-width: 480px) {
      body.body-vn .dc-heading__seemore {
        min-width: 115px;
        margin-left: 16px;
        display: flex;
        justify-content: flex-end; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      body.body-vn .dc-heading__seemore {
        min-width: 110px;
        margin-left: 16px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      body.body-vn .dc-heading__seemore {
        min-width: 110px;
        margin-left: 16px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      body.body-vn .dc-heading__seemore {
        min-width: 110px;
        margin-left: 16px; } }

.dc-body {
  display: flex;
  flex-direction: column; }
  @media (max-width: 480px) {
    .dc-body--career-detail {
      padding-bottom: 48px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-body--career-detail {
      padding-bottom: 48px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-body--career-detail {
      padding-bottom: 48px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-body--career-detail {
      padding-bottom: 48px; } }

.dc-main {
  flex-grow: 1;
  min-height: calc(100vh - 192px); }
  @media (max-width: 768px) {
    .dc-main {
      min-height: calc(100vh - 248px); } }

@media (min-width: 1310px) {
  .container {
    max-width: 1200px; } }

.dc-heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px; }
  @media (max-width: 480px) {
    .dc-heading {
      margin-bottom: 30px;
      align-items: flex-end; } }
  .dc-heading__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 0;
    font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif; }
    @media (max-width: 480px) {
      .dc-heading__title {
        letter-spacing: -1.2px;
        line-height: 39px; } }
  .dc-heading__seemore {
    font-size: 16px;
    font-family: "SFProText Medium", sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 12px;
    transition: all .2s ease;
    letter-spacing: -0.46px;
    font-weight: 500; }
    .dc-heading__seemore:hover {
      color: #000; }
    @media (max-width: 480px) {
      .dc-heading__seemore {
        line-height: 24px;
        padding-right: 12px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-heading__seemore {
        line-height: 24px;
        padding-right: 12px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-heading__seemore {
        line-height: 24px;
        padding-right: 12px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-heading__seemore {
        line-height: 24px;
        padding-right: 12px; } }
    .dc-heading__seemore .dc-arrow {
      border-top: 1px solid #000;
      border-left: 1px solid #000;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(135deg); }

.dc-arrow {
  transform: rotate(135deg);
  right: 20px;
  top: 28px;
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: transparent;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff; }

.dc-page-title {
  font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 70px;
  line-height: 76px;
  margin-bottom: 0; }
  @media (max-width: 480px) {
    .dc-page-title {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 24px;
      letter-spacing: -0.5px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-page-title {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 24px;
      letter-spacing: -0.5px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-page-title {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 24px;
      letter-spacing: -0.5px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-page-title {
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 24px;
      letter-spacing: -0.5px; } }

.dc-page-top {
  margin-bottom: 60px; }
  @media (max-width: 768px) {
    .dc-page-top {
      margin-bottom: 0; } }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    .dc-page-top {
      margin-bottom: 0; } }

@media (min-width: 570px) {
  .modal-dialog {
    max-width: 530px; } }

[type="radio"]:checked, [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px; }

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000; }
  [type="radio"]:checked + label::before,
  [type="radio"]:not(:checked) + label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #fff;
    border: 1px solid #d6d6d6;
    transition: all .2s ease; }

[type="radio"]:checked + label:before {
  background: #08b33b;
  border: 1px solid #08b33b; }

.dc-page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  font-size: 20px;
  color: #000;
  padding: 16px; }
  @media (max-width: 768px) {
    .dc-page-404 {
      font-size: 16px;
      margin-top: 100px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .dc-page-404 {
      margin-top: 24px; } }
  .dc-page-404 h2 {
    font-size: 44px;
    margin-bottom: 8px; }
    @media (max-width: 768px) {
      .dc-page-404 h2 {
        font-size: 32px; } }
  .dc-page-404 p {
    color: #616161;
    margin-bottom: 32px;
    text-align: center; }
    @media (max-width: 768px) {
      .dc-page-404 p {
        margin-bottom: 16px; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      .dc-page-404 p {
        margin-bottom: 8px; } }
  .dc-page-404 a {
    text-decoration: underline; }
    .dc-page-404 a:not(:last-child) {
      margin-right: 32px; }

.text-primary {
  color: #08b33b !important; }

.btn-link:hover {
  color: #000; }

.form-group {
  margin-bottom: 0; }

@media (max-width: 480px) {
  .wow {
    animation-name: none !important;
    visibility: visible !important; } }

#inputPosition {
  margin-top: 2px; }

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #000; }

.btn-primary {
  background-color: #08b33b;
  border-color: #08b33b; }
  .btn-primary:hover, .btn-primary:active {
    background-color: #08b33b !important;
    border-color: #08b33b !important; }
  .btn-primary:focus {
    box-shadow: none; }

.owl-stage-outer {
  width: calc(100vw + 15px); }

.owl-carousel .owl-stage {
  padding-left: 0 !important; }

.lity {
  background: rgba(0, 0, 0, 0.8); }

@media (max-width: 480px) {
  .lity-close, .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
    position: absolute;
    top: -3rem; } }

@media only screen and (orientation: landscape) and (max-width: 812px) {
  .lity-close, .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
    position: absolute;
    top: -3rem; } }

@media only screen and (orientation: landscape) and (max-width: 736px) {
  .lity-close, .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
    position: absolute;
    top: -3rem; } }

@media only screen and (orientation: landscape) and (max-width: 667px) {
  .lity-close, .lity-close:hover, .lity-close:focus, .lity-close:active, .lity-close:visited {
    position: absolute;
    top: -3rem; } }

.dc-header.fixed-header {
  background: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  animation: slideInDown .4s ease; }
  .dc-header.fixed-header .dc-menu-mobile .button-black {
    display: block; }
  .dc-header.fixed-header .dc-menu-mobile .button-white {
    display: none; }
  .dc-header.fixed-header .dc-menu-mobile .navbar-brand {
    color: #000; }
  .dc-header.fixed-header .dc-menu__left .nav-item.out-link {
    background: url("../images/ic-outlink.svg") no-repeat;
    background-position: 95% 40%; }
  .dc-header.fixed-header .dc-menu__left .nav-item > ul a:hover {
    color: #000; }
  .dc-header.fixed-header .dc-menu__left .nav-link {
    color: #000; }
    .dc-header.fixed-header .dc-menu__left .nav-link::after {
      background: #000; }
  .dc-header.fixed-header .dc-menu__language .nav-link {
    color: #000; }
  .dc-header.fixed-header .dc-menu__language .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.3);
    transition: all .1s ease; }
    .dc-header.fixed-header .dc-menu__language .nav-item .nav-link:hover {
      color: #000; }
  .dc-header.fixed-header .dc-menu__language .nav-item.active .nav-link {
    color: #000; }
  .dc-header.fixed-header .dc-menu__language .nav-item:first-child .nav-link::after {
    background: rgba(0, 0, 0, 0.3); }

.dc-menu {
  background: #fff;
  line-height: 5.18;
  padding: 0 67px 0 7px;
  position: relative;
  z-index: 5; }
  .dc-menu .logo-white {
    display: none; }
  .dc-menu .logo-white,
  .dc-menu .logo-black {
    max-height: 20px; }
  @media (max-width: 969px) {
    .dc-menu {
      display: none; } }
  .dc-menu__logo {
    margin-top: -2px; }
  .dc-menu__left .nav-item {
    position: relative; }
    .dc-menu__left .nav-item > ul {
      position: absolute;
      width: 300px;
      z-index: 10;
      border-radius: 2px;
      left: 20px;
      opacity: 0;
      visibility: hidden;
      transition: all .2s ease;
      transform: translateY(0); }
      .dc-menu__left .nav-item > ul li {
        line-height: 32px; }
      .dc-menu__left .nav-item > ul a {
        color: #999;
        font-weight: 600;
        font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif;
        padding: 10px 0;
        position: relative; }
        .dc-menu__left .nav-item > ul a:hover {
          color: #000; }
          .dc-menu__left .nav-item > ul a:hover::after {
            display: block; }
    .dc-menu__left .nav-item:hover > ul {
      visibility: visible;
      opacity: 1;
      transform: translateY(-20px); }
  .dc-menu__left .nav-link {
    color: #000;
    font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 17px;
    padding: 0 22px;
    position: relative; }
    .dc-menu__left .nav-link::after {
      content: '';
      position: absolute;
      top: calc(50% + 12px);
      width: 0;
      left: 0;
      margin-left: 20px;
      height: 3px;
      background: #000;
      transition: all .3s ease; }
  .dc-menu__left .nav-item.active .nav-link::after {
    width: calc(100% - 40px); }
  .dc-menu__left .nav-item:not(:first-child):hover .nav-link::after {
    width: calc(100% - 40px); }
  .dc-menu__left .nav-item.out-link {
    background: url("../images/ic-outlink.svg") no-repeat;
    background-position: 95% 40%; }
  .dc-menu__language .nav-link {
    color: #000;
    font-size: 14px;
    padding: 0;
    font-weight: 600;
    font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif; }
  .dc-menu__language .nav-item .nav-link {
    color: rgba(0, 0, 0, 0.3);
    transition: all .1s ease; }
    .dc-menu__language .nav-item .nav-link:hover {
      color: #000; }
  .dc-menu__language .nav-item.active .nav-link {
    color: #000; }
  .dc-menu__language .nav-item:first-child .nav-link {
    padding-right: 12px;
    position: relative; }
    .dc-menu__language .nav-item:first-child .nav-link::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 9px;
      background: rgba(0, 0, 0, 0.3); }
  .dc-menu__language .nav-item:last-child .nav-link {
    padding-left: 8px; }
  @media (max-width: 1170px) {
    .dc-menu__language {
      position: absolute;
      width: calc(100% - 40px);
      bottom: 37px;
      left: 20px;
      padding-top: 20px;
      border-top: 1px solid #f0f0f0; }
      .dc-menu__language .nav-item:first-child {
        padding-left: 24px; }
        .dc-menu__language .nav-item:first-child .nav-link::before {
          position: absolute;
          content: '';
          left: -24px;
          top: 0;
          width: 20px;
          height: 20px;
          background: url("../images/ic-language.svg") center no-repeat; }
        .dc-menu__language .nav-item:first-child .nav-link::after {
          width: 2px;
          height: 2px;
          border-radius: 50%;
          background: #000; } }
  @media (max-width: 480px) {
    .dc-menu__language {
      padding-top: 18px;
      padding-bottom: 33px;
      position: absolute;
      margin-top: 10px;
      bottom: 0;
      z-index: 3;
      background: #fff;
      width: calc(100% - 32px);
      left: 16px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-menu__language {
      padding-top: 18px;
      padding-bottom: 33px;
      padding-left: 16px;
      position: fixed;
      left: 16px;
      margin-top: 10px;
      bottom: 0;
      z-index: 3;
      background: #fff;
      width: calc(100% - 32px); } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-menu__language {
      padding-top: 18px;
      padding-bottom: 33px;
      padding-left: 16px;
      position: fixed;
      left: 16px;
      margin-top: 10px;
      bottom: 0;
      z-index: 3;
      background: #fff;
      width: calc(100% - 32px); } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-menu__language {
      padding-top: 18px;
      padding-bottom: 33px;
      padding-left: 16px;
      position: fixed;
      left: 16px;
      margin-top: 10px;
      bottom: 0;
      z-index: 3;
      background: #fff;
      width: calc(100% - 32px); } }
  .dc-menu--homepage {
    background: transparent; }
    .dc-menu--homepage .logo-black {
      display: none; }
    .dc-menu--homepage .logo-white {
      display: inline-block; }
    .dc-menu--homepage .dc-menu__left .nav-item.out-link {
      background: url("../images/ic-outlink-white.svg") no-repeat;
      background-position: 95% 40%; }
    .dc-menu--homepage .dc-menu__left .nav-item > ul a:hover {
      color: #fff; }
    .dc-menu--homepage .dc-menu__left .nav-link {
      color: #fff; }
      .dc-menu--homepage .dc-menu__left .nav-link::after {
        background: #fff; }
    .dc-menu--homepage .dc-menu__language .nav-link {
      color: #fff; }
    .dc-menu--homepage .dc-menu__language .nav-item .nav-link {
      color: rgba(255, 255, 255, 0.3);
      transition: all .1s ease; }
      .dc-menu--homepage .dc-menu__language .nav-item .nav-link:hover {
        color: #fff; }
    .dc-menu--homepage .dc-menu__language .nav-item.active .nav-link {
      color: #fff; }
    .dc-menu--homepage .dc-menu__language .nav-item:first-child .nav-link::after {
      background: rgba(255, 255, 255, 0.3); }

.dc-menu-mobile {
  position: relative;
  z-index: 1040;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .dc-menu-mobile .navbar-brand {
    color: #000; }
  .dc-menu-mobile__navbar {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 90px; }
  .dc-menu-mobile--home .button-black {
    display: none; }
  .dc-menu-mobile--home .navbar-brand {
    color: #fff; }
  .dc-menu-mobile__close {
    position: absolute;
    top: 0;
    right: 0; }
  .dc-menu-mobile .navbar-brand {
    font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif;
    transition: all .2s ease;
    font-size: 20px; }
  .dc-menu-mobile__navbar .nav-item {
    position: relative; }
    .dc-menu-mobile__navbar .nav-item:first-child {
      display: none; }
    .dc-menu-mobile__navbar .nav-item > ul {
      padding: 10px; }
      @media (max-width: 480px) {
        .dc-menu-mobile__navbar .nav-item > ul {
          padding: 16px 10px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-menu-mobile__navbar .nav-item > ul {
          padding: 0 10px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-menu-mobile__navbar .nav-item > ul {
          padding: 0 10px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-menu-mobile__navbar .nav-item > ul {
          padding: 0 10px; } }
      .dc-menu-mobile__navbar .nav-item > ul li {
        line-height: 32px; }
      .dc-menu-mobile__navbar .nav-item > ul a {
        color: #000;
        font-family: "SFProText", sans-serif;
        padding: 10px 0;
        position: relative;
        font-size: 16px;
        line-height: 24px; }
        .dc-menu-mobile__navbar .nav-item > ul a:hover {
          color: #1e1e1e; }
    .dc-menu-mobile__navbar .nav-item .nav-link {
      font-size: 24px;
      font-weight: 600;
      padding: 8px 0;
      display: inline-block;
      position: relative;
      line-height: 35px; }
      @media (max-width: 480px) {
        .dc-menu-mobile__navbar .nav-item .nav-link {
          padding: 4px 0;
          font-family: "SFProText Bold", sans-serif; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-menu-mobile__navbar .nav-item .nav-link {
          padding: 0;
          font-family: "SFProText Bold", sans-serif; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-menu-mobile__navbar .nav-item .nav-link {
          padding: 0;
          font-family: "SFProText Bold", sans-serif; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-menu-mobile__navbar .nav-item .nav-link {
          padding: 0;
          font-family: "SFProText Bold", sans-serif; } }
      @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape) {
        .dc-menu-mobile__navbar .nav-item .nav-link {
          padding: 0; } }
      @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        .dc-menu-mobile__navbar .nav-item .nav-link {
          padding: 0; } }
    .dc-menu-mobile__navbar .nav-item.active .nav-link::after {
      position: absolute;
      content: '';
      top: 20px;
      left: calc(100% + 16px);
      background: #08b33b;
      width: 60px;
      height: 4px; }
  .dc-menu-mobile__collapse {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 100vw;
    padding-right: 1rem;
    padding-left: 20px;
    visibility: hidden;
    transition: all .25s ease;
    background: #fff;
    padding-top: 60px;
    overflow-y: hidden;
    z-index: 100; }
    @media (max-width: 480px) {
      .dc-menu-mobile__collapse {
        padding-top: 50px; } }
    .dc-menu-mobile__collapse.open {
      right: 0;
      visibility: visible; }

.dc-footer {
  color: #1e1e1e; }
  .dc-footer .container {
    border-top: 1px solid #e2e2e2;
    padding: 38px 0; }
    @media (max-width: 480px) {
      .dc-footer .container {
        padding: 0 15px 46px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-footer .container {
        padding: 0 15px 46px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-footer .container {
        padding: 0 15px 46px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-footer .container {
        padding: 0 15px 46px; } }
  .dc-footer__left h4 {
    font-weight: 700;
    margin: 10px 0; }
  .dc-footer__left p {
    max-width: 445px; }
  .dc-footer__right {
    position: relative; }
  .dc-footer__link {
    padding-top: 20px;
    margin-top: -20px;
    padding-right: 47px; }
    @media (max-width: 480px) {
      .dc-footer__link {
        padding-right: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-footer__link {
        padding-right: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-footer__link {
        padding-right: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-footer__link {
        padding-right: 0; } }
    .dc-footer__link span {
      font-family: "SFProText", sans-serif; }
    .dc-footer__link:hover .dc-footer__social-list {
      display: block;
      opacity: 1; }
      .dc-footer__link:hover .dc-footer__social-list li {
        margin-bottom: 12px;
        display: block; }
        .dc-footer__link:hover .dc-footer__social-list li:last-child {
          margin-bottom: 0; }
  .dc-footer__social {
    position: relative;
    padding-left: 0;
    margin-top: -16px; }
    @media (max-width: 480px) {
      .dc-footer__social {
        padding-left: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-footer__social {
        padding-left: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-footer__social {
        padding-left: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-footer__social {
        padding-left: 0; } }
    .dc-footer__social .list-inline-item {
      padding-top: 16px; }
      .dc-footer__social .list-inline-item:not(:last-child) {
        margin-right: 10px; }
    .dc-footer__social-list {
      font-family: "SFProText", sans-serif;
      position: absolute;
      padding: 20px 16px;
      width: 204px;
      min-height: 90px;
      background: #fff;
      font-size: 13px;
      border: 1px solid #e1e1e1;
      left: -41px;
      bottom: 42px;
      z-index: 3;
      display: none;
      opacity: 0;
      transition: all .3s ease; }
      @media (max-width: 480px) {
        .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-footer__social-list {
          left: 0; } }
      .dc-footer__social-list li a {
        color: #999; }
        .dc-footer__social-list li a:hover {
          color: #1e1e1e; }
      .dc-footer__social-list::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 11px solid transparent;
        border-right: 11px solid transparent;
        border-bottom: 11px solid #fff;
        background: #fff;
        top: calc(100% - 11px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 2; }
      .dc-footer__social-list::after {
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        border: 1px solid #e2e2e2;
        background: #fff;
        top: calc(100% - 6px);
        left: 50%;
        transform: translateX(-50%) rotate(-45deg); }
      @media (max-width: 480px) {
        .dc-footer__social-list::before, .dc-footer__social-list::after {
          left: 14px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-footer__social-list::before, .dc-footer__social-list::after {
          left: 14px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-footer__social-list::before, .dc-footer__social-list::after {
          left: 14px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-footer__social-list::before, .dc-footer__social-list::after {
          left: 14px; } }
      @media screen and (max-width: 640px) and (orientation: landscape) {
        .dc-footer__social-list::before, .dc-footer__social-list::after {
          left: 14px; } }
    .dc-footer__social-facebook:hover .dc-footer__social-list, .dc-footer__social-linkin:hover .dc-footer__social-list {
      display: block;
      opacity: 1; }
      .dc-footer__social-facebook:hover .dc-footer__social-list li:last-child, .dc-footer__social-linkin:hover .dc-footer__social-list li:last-child {
        display: block;
        margin-top: 12px; }
    .dc-footer__social img {
      cursor: pointer;
      max-width: 27px; }
    .dc-footer__social-facebook .dc-footer__social-list {
      left: -89px; }
      @media (max-width: 480px) {
        .dc-footer__social-facebook .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-footer__social-facebook .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-footer__social-facebook .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-footer__social-facebook .dc-footer__social-list {
          left: 0; } }
    .dc-footer__social-linkin .dc-footer__social-list {
      left: -49px; }
      @media (max-width: 480px) {
        .dc-footer__social-linkin .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-footer__social-linkin .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-footer__social-linkin .dc-footer__social-list {
          left: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-footer__social-linkin .dc-footer__social-list {
          left: 0; } }
      @media (max-width: 480px) {
        .dc-footer__social-linkin .dc-footer__social-list::before, .dc-footer__social-linkin .dc-footer__social-list::after {
          left: 52px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-footer__social-linkin .dc-footer__social-list::before, .dc-footer__social-linkin .dc-footer__social-list::after {
          left: 52px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-footer__social-linkin .dc-footer__social-list::before, .dc-footer__social-linkin .dc-footer__social-list::after {
          left: 52px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-footer__social-linkin .dc-footer__social-list::before, .dc-footer__social-linkin .dc-footer__social-list::after {
          left: 52px; } }
      @media screen and (max-width: 640px) and (orientation: landscape) {
        .dc-footer__social-linkin .dc-footer__social-list::before, .dc-footer__social-linkin .dc-footer__social-list::after {
          left: 52px; } }
  @media (max-width: 480px) {
    .dc-footer {
      font-size: 13px; }
      .dc-footer__top {
        padding: 18px 30px;
        width: calc(100vw + 30px);
        margin-left: -30px;
        border-bottom: 1px solid #e2e2e2; }
      .dc-footer__social {
        padding: 0;
        margin: 0;
        margin-left: -3px; }
        .dc-footer__social .list-inline-item:not(:last-child) {
          padding-top: 0; }
      .dc-footer__links {
        margin: 24px 0 16px; }
        .dc-footer__links .list-inline-item {
          position: relative; }
          .dc-footer__links .list-inline-item:not(:last-child) {
            padding-right: 32px;
            margin-right: 0; }
            .dc-footer__links .list-inline-item:not(:last-child)::after {
              position: absolute;
              content: '';
              top: 4px;
              right: 15px;
              background: #e2e2e2;
              width: 1px;
              height: 12px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-footer__links .list-inline-item {
      position: relative; }
      .dc-footer__links .list-inline-item:not(:last-child) {
        padding-right: 32px;
        margin-right: 0; }
        .dc-footer__links .list-inline-item:not(:last-child)::after {
          position: absolute;
          content: '';
          top: 4px;
          right: 15px;
          background: #e2e2e2;
          width: 1px;
          height: 12px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-footer__links .list-inline-item {
      position: relative; }
      .dc-footer__links .list-inline-item:not(:last-child) {
        padding-right: 32px;
        margin-right: 0; }
        .dc-footer__links .list-inline-item:not(:last-child)::after {
          position: absolute;
          content: '';
          top: 4px;
          right: 15px;
          background: #e2e2e2;
          width: 1px;
          height: 12px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-footer__links .list-inline-item {
      position: relative; }
      .dc-footer__links .list-inline-item:not(:last-child) {
        padding-right: 32px;
        margin-right: 0; }
        .dc-footer__links .list-inline-item:not(:last-child)::after {
          position: absolute;
          content: '';
          top: 4px;
          right: 15px;
          background: #e2e2e2;
          width: 1px;
          height: 12px; } }

.dc-news [class*='col-'] {
  align-self: flex-start;
  max-width: 550px; }
  @media (max-width: 1170px) {
    .dc-news [class*='col-'] {
      max-width: 100%; } }
  .dc-news [class*='col-'] img {
    height: 550px; }
    @media (max-width: 1170px) {
      .dc-news [class*='col-'] img {
        height: 420px; } }
    @media (max-width: 480px) {
      .dc-news [class*='col-'] img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-news [class*='col-'] img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-news [class*='col-'] img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-news [class*='col-'] img {
        height: 160px; } }
  .dc-news [class*='col-']:nth-child(3) {
    margin-top: -120px; }
    @media (max-width: 1170px) {
      .dc-news [class*='col-']:nth-child(3) {
        margin-top: 0; } }
  .dc-news [class*='col-']:first-child img, .dc-news [class*='col-']:last-child img {
    height: 420px; }
    @media (max-width: 480px) {
      .dc-news [class*='col-']:first-child img, .dc-news [class*='col-']:last-child img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-news [class*='col-']:first-child img, .dc-news [class*='col-']:last-child img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-news [class*='col-']:first-child img, .dc-news [class*='col-']:last-child img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-news [class*='col-']:first-child img, .dc-news [class*='col-']:last-child img {
        height: 160px; } }

.dc-news__item {
  margin-bottom: 120px; }
  @media (max-width: 1170px) {
    .dc-news__item {
      margin-bottom: 40px; } }
  .dc-news__item:hover img {
    transform: scale(1.1); }

.dc-news__img {
  position: relative;
  overflow: hidden; }
  .dc-news__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1);
    transition: all .3s ease; }

.dc-news__body {
  color: #616161;
  font-family: "SFProText", sans-serif; }

.dc-news__header {
  margin: 24px 0 21px;
  font-size: 30px;
  font-family: "SFProText Bold", sans-serif;
  letter-spacing: -0.3px; }
  @media (max-width: 969px) {
    .dc-news__header {
      font-size: 20px;
      margin: 25px 0 10px; } }
  @media (max-width: 480px) {
    .dc-news__header {
      margin: 16px 0 10px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-news__header {
      margin: 16px 0 10px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-news__header {
      margin: 16px 0 10px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-news__header {
      margin: 16px 0 10px; } }
  .dc-news__header a {
    color: #1e1e1e; }
    .dc-news__header a:hover {
      color: #000; }

.dc-news__meta {
  display: block;
  margin-bottom: 15px;
  line-height: 25px;
  font-size: 14px; }
  @media (max-width: 969px) {
    .dc-news__meta {
      font-size: 15px;
      margin-bottom: 10px; } }
  @media (max-width: 480px) {
    .dc-news__meta {
      line-height: 22px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-news__meta {
      line-height: 22px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-news__meta {
      line-height: 22px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-news__meta {
      line-height: 22px; } }

.dc-news__desc {
  font-size: 16px;
  line-height: 25px;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (max-width: 969px) {
    .dc-news__desc {
      font-size: 15px;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-news__desc {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-news__desc {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-news__desc {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 22px; } }

.dc-hero {
  height: 840px;
  margin-bottom: 84px;
  position: relative;
  z-index: 3;
  margin-top: -88px; }
  @media (max-width: 1170px) {
    .dc-hero {
      height: 600px;
      margin-top: -63px; } }
  @media (max-width: 480px) {
    .dc-hero {
      height: 562px;
      margin-bottom: 45px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-hero {
      height: 400px;
      margin-bottom: 45px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-hero {
      height: 400px;
      margin-bottom: 45px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-hero {
      height: 400px;
      margin-bottom: 45px; } }
  .dc-hero::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2; }
  .dc-hero__video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1; }
  .dc-hero__content {
    position: absolute;
    top: 50%;
    margin-top: 23px;
    transform: translateY(-50%);
    z-index: 3; }
    @media (max-width: 1170px) {
      .dc-hero__content {
        margin: 0; } }
    @media (max-width: 480px) {
      .dc-hero__content {
        padding: 0 40px; } }
    @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      .dc-hero__content {
        padding: 0; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      .dc-hero__content {
        padding: 0;
        padding-top: 50px; } }
    .dc-hero__content h1 {
      font-size: 90px;
      line-height: 110px;
      margin-bottom: 40px;
      font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif; }
      @media (max-width: 1170px) {
        .dc-hero__content h1 {
          font-size: 64px;
          line-height: 70px;
          margin-bottom: 30px; } }
      @media (max-width: 480px) {
        .dc-hero__content h1 {
          font-size: 38px;
          line-height: 42px;
          margin-bottom: 20px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-hero__content h1 {
          font-size: 38px;
          line-height: 42px;
          margin-bottom: 20px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-hero__content h1 {
          font-size: 38px;
          line-height: 42px;
          margin-bottom: 20px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-hero__content h1 {
          font-size: 38px;
          line-height: 42px;
          margin-bottom: 20px; } }
    .dc-hero__content p {
      font-size: 20px;
      font-family: "SFProText", sans-serif;
      margin-bottom: 40px;
      line-height: 30px; }
      @media (max-width: 1170px) {
        .dc-hero__content p {
          margin-bottom: 30px; } }
      @media (max-width: 480px) {
        .dc-hero__content p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-hero__content p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-hero__content p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-hero__content p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0; } }
  @media (max-width: 480px) {
    .dc-hero__center {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-hero__center {
      position: absolute;
      left: -60px;
      top: calc(100% + 39px);
      width: 100vw;
      z-index: 2; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-hero__center {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      top: initial; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-hero__center {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      top: initial; } }
  .dc-hero__center .list-inline-item {
    position: relative;
    color: #fff;
    transition: all .1s ease; }
    @media (max-width: 480px) {
      .dc-hero__center .list-inline-item {
        margin-left: -1px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-hero__center .list-inline-item {
        margin-left: -1px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-hero__center .list-inline-item {
        margin-left: -1px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-hero__center .list-inline-item {
        margin-left: -1px; } }
    .dc-hero__center .list-inline-item:hover {
      background: #fff; }
      .dc-hero__center .list-inline-item:hover > a {
        color: #000; }
      .dc-hero__center .list-inline-item:hover .dc-arrow {
        border-top: 1px solid #000;
        border-left: 1px solid #000;
        cursor: pointer; }
    .dc-hero__center .list-inline-item:not(:last-child) {
      margin-right: 0; }
    .dc-hero__center .list-inline-item:last-child {
      margin-left: -5px; }
      @media (max-width: 480px) {
        .dc-hero__center .list-inline-item:last-child {
          margin-left: -1px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-hero__center .list-inline-item:last-child {
          margin-left: -5px; }
          .dc-hero__center .list-inline-item:last-child > a {
            border-right: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-hero__center .list-inline-item:last-child {
          margin-left: -1px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-hero__center .list-inline-item:last-child {
          margin-left: -1px; } }
    .dc-hero__center .list-inline-item > a {
      border: 1px solid #fff;
      width: 200px;
      height: 60px;
      line-height: 60px;
      padding-left: 20px;
      font-size: 20px;
      display: inline-block;
      color: #fff;
      font-family: "SFProText Bold", sans-serif;
      font-weight: 600; }
      @media (max-width: 480px) {
        .dc-hero__center .list-inline-item > a {
          width: calc(50vw + 2px); } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-hero__center .list-inline-item > a {
          width: calc(50vw + 2px); } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-hero__center .list-inline-item > a {
          width: calc(50vw + 2px); } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-hero__center .list-inline-item > a {
          width: calc(50vw + 2px); } }

.dc-newjob {
  margin-bottom: 90px; }
  @media (max-width: 480px) {
    .dc-newjob {
      margin-bottom: 50px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-newjob {
      margin-bottom: 50px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-newjob {
      margin-bottom: 50px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-newjob {
      margin-bottom: 50px; } }
  @media (max-width: 480px) {
    .dc-newjob .owl-theme .owl-dots .owl-dot span {
      width: 6px;
      height: 6px;
      margin: 4px;
      background: #e2e2e2; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-newjob .owl-theme .owl-dots .owl-dot span {
      width: 6px;
      height: 6px;
      margin: 4px;
      background: #e2e2e2; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-newjob .owl-theme .owl-dots .owl-dot span {
      width: 6px;
      height: 6px;
      margin: 4px;
      background: #e2e2e2; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-newjob .owl-theme .owl-dots .owl-dot span {
      width: 6px;
      height: 6px;
      margin: 4px;
      background: #e2e2e2; } }
  .dc-newjob .owl-theme .owl-dots .owl-dot.active span {
    background: #08b33b; }
  .dc-newjob__item {
    font-family: "SFProText", sans-serif;
    border: 1px solid #e8e7e8;
    padding: 46px 30px 38px;
    height: 420px;
    display: flex;
    flex-direction: column;
    margin-left: -1px;
    transition: all .3s ease;
    color: #616161; }
    @media (max-width: 480px) {
      .dc-newjob__item {
        height: 315px;
        margin: 0;
        padding: 26px 30px 28px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-newjob__item {
        height: 315px;
        margin: 0;
        padding: 26px 30px 28px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-newjob__item {
        height: 315px;
        margin: 0;
        padding: 26px 30px 28px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-newjob__item {
        height: 315px;
        margin: 0;
        padding: 26px 30px 28px; } }
    .dc-newjob__item:hover {
      color: #fff;
      background: #08b33b; }
      .dc-newjob__item:hover .dc-newjob__header {
        color: #fff; }
      .dc-newjob__item:hover .dc-newjob__meta .list-inline-item:first-child::after {
        background: #fff; }
  .dc-newjob__header {
    font-size: 26px;
    font-weight: 600;
    font-family: "SFProText Bold", sans-serif;
    margin-bottom: 16px;
    line-height: 30px;
    color: #000;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
  .dc-newjob ul {
    padding-left: 0;
    list-style: none;
    font-size: 16px; }
  .dc-newjob__text,
  .dc-newjob p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.16px;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 480px) {
      .dc-newjob__text,
      .dc-newjob p {
        max-height: 100px;
        line-height: 24px;
        letter-spacing: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-newjob__text,
      .dc-newjob p {
        max-height: 100px;
        line-height: 24px;
        letter-spacing: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-newjob__text,
      .dc-newjob p {
        max-height: 100px;
        line-height: 24px;
        letter-spacing: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-newjob__text,
      .dc-newjob p {
        max-height: 100px;
        line-height: 24px;
        letter-spacing: 0; } }
  .dc-newjob__meta {
    margin-top: auto;
    display: flex;
    align-items: center; }
    .dc-newjob__meta .list-inline-item:not(:last-child) {
      margin-right: 20px;
      position: relative; }
      .dc-newjob__meta .list-inline-item:not(:last-child)::after {
        position: absolute;
        content: '';
        width: 1px;
        height: 12px;
        background: #d8d8d8;
        top: 5px;
        right: -10px; }

.dc-hire {
  background: #fbfbfb;
  padding: 76px 0 71px;
  margin-bottom: 82px; }
  @media (max-width: 480px) {
    .dc-hire {
      padding: 45px 0 60px;
      margin-bottom: 40px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-hire {
      padding: 45px 0 60px;
      margin-bottom: 40px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-hire {
      padding: 45px 0 60px;
      margin-bottom: 40px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-hire {
      padding: 45px 0 60px;
      margin-bottom: 40px; } }
  .dc-hire__content h3 {
    font-size: 28px; }
    @media (max-width: 1170px) {
      .dc-hire__content h3 {
        font-size: 20px; } }
  .dc-hire__step {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-family: "SFProText Bold", sans-serif;
    position: relative; }
    .dc-hire__step::after {
      position: absolute;
      content: '';
      width: 80px;
      height: 10px;
      background: url("../images/ic-step-arrow.svg") center no-repeat;
      top: 50%;
      transform: translateY(-50%);
      left: 100%; }
      @media (max-width: 1170px) {
        .dc-hire__step::after {
          height: 50px;
          width: 10px;
          left: 50vw;
          top: calc(100% - 50px);
          background: url("../images/ic-step-arrow-mw.svg") center no-repeat; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-hire__step::after {
          left: 53%; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-hire__step::after {
          left: 53%; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-hire__step::after {
          left: 53%; } }
    .dc-hire__step img {
      padding-right: 28px; }
    .dc-hire__step span {
      color: #08b33b;
      font-size: 15px;
      font-weight: 600;
      line-height: 30px;
      font-family: "SFProText Bold", sans-serif; }
  @media (max-width: 1170px) {
    .dc-hire [class*='col'] .dc-hire__step img {
      max-width: 110px; } }
  @media (max-width: 1170px) {
    .dc-hire [class*='col']:first-child .dc-hire__step {
      margin-left: -16px; } }
  @media (max-width: 480px) {
    .dc-hire [class*='col']:first-child .dc-hire__step {
      margin-left: -36px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-hire [class*='col']:first-child .dc-hire__step {
      margin-left: -36px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-hire [class*='col']:first-child .dc-hire__step {
      margin-left: -36px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-hire [class*='col']:first-child .dc-hire__step {
      margin-left: -36px; } }
  .dc-hire [class*='col']:first-child .dc-hire__step::after {
    left: 80%; }
    @media (max-width: 1170px) {
      .dc-hire [class*='col']:first-child .dc-hire__step::after {
        top: calc(100% + 25px);
        left: 61vw;
        transform: translateX(-50%); } }
    @media (max-width: 480px) {
      .dc-hire [class*='col']:first-child .dc-hire__step::after {
        left: 60vw; } }
    @media screen and (max-width: 375px) {
      .dc-hire [class*='col']:first-child .dc-hire__step::after {
        left: 61vw; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-hire [class*='col']:first-child .dc-hire__step::after {
        left: 56%; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-hire [class*='col']:first-child .dc-hire__step::after {
        left: 57%; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-hire [class*='col']:first-child .dc-hire__step::after {
        left: 57%; } }
  @media (max-width: 1170px) {
    .dc-hire [class*='col']:nth-child(2) .dc-hire__step {
      padding: 110px 0;
      position: relative; } }
  .dc-hire [class*='col']:last-child .dc-hire__step::after {
    display: none; }

.dc-home-blog {
  margin-bottom: 140px; }
  @media (max-width: 1170px) {
    .dc-home-blog {
      margin-bottom: 34px; } }
  .dc-home-blog [class*='col-']:nth-child(2) {
    position: relative; }
    @media (max-width: 1170px) {
      .dc-home-blog [class*='col-']:nth-child(2) {
        padding: 0 15px; } }
    .dc-home-blog [class*='col-']:nth-child(2)::before, .dc-home-blog [class*='col-']:nth-child(2)::after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 117px;
      background: #efefef; }
      @media (max-width: 1170px) {
        .dc-home-blog [class*='col-']:nth-child(2)::before, .dc-home-blog [class*='col-']:nth-child(2)::after {
          display: none; } }
    .dc-home-blog [class*='col-']:nth-child(2)::before {
      left: 4px; }
    .dc-home-blog [class*='col-']:nth-child(2)::after {
      right: -4px; }
  @media (max-width: 1170px) {
    .dc-home-blog [class*='col-']:not(:last-child) {
      border-bottom: 1px solid #efefef;
      padding-bottom: 34px; } }
  @media (max-width: 1170px) {
    .dc-home-blog [class*='col-']:not(:first-child) {
      padding-top: 35px; } }
  .dc-home-blog__avatar {
    margin-right: 21px; }
    .dc-home-blog__avatar img {
      min-width: 50px;
      height: 50px;
      object-fit: cover; }
  .dc-home-blog__header {
    font-size: 20px;
    line-height: 26px;
    font-family: "SFProText Bold", sans-serif; }
    .dc-home-blog__header a:hover {
      color: #000; }
  .dc-home-blog__meta, .dc-home-blog__position {
    color: #aaa;
    font-size: 16px;
    font-family: "SFProText", sans-serif;
    line-height: 25px;
    letter-spacing: -0.12px; }
  .dc-home-blog__meta .list-inline-item:first-child {
    position: relative;
    margin-right: 10px; }
    .dc-home-blog__meta .list-inline-item:first-child::after {
      position: absolute;
      content: '';
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      background: #aaa;
      width: 1px;
      height: 13px; }
  .dc-home-blog__category {
    color: #08b33b;
    letter-spacing: -.16px;
    font-size: 16px;
    margin-bottom: 11px;
    display: block;
    font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif; }

@media (max-width: 480px) {
  #hotjob-carousel .dc-newjob__text {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; } }
  @media only screen and (max-width: 480px) and (orientation: landscape) and (max-width: 812px) {
    #hotjob-carousel .dc-newjob__text {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } }
  @media only screen and (max-width: 480px) and (orientation: landscape) and (max-width: 736px) {
    #hotjob-carousel .dc-newjob__text {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } }
  @media only screen and (max-width: 480px) and (orientation: landscape) and (max-width: 667px) {
    #hotjob-carousel .dc-newjob__text {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } }

.dc-career {
  padding: 70px 0 100px; }
  @media (max-width: 480px) {
    .dc-career {
      padding: 35px 0 50px; } }
  @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .dc-career {
      padding: 35px 0 50px; } }
  @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
    .dc-career {
      padding: 35px 0 50px; } }
  .dc-career__item span {
    color: #08b33b; }
  .dc-career .dc-newjob__item {
    padding-right: 100px; }
    @media (max-width: 480px) {
      .dc-career .dc-newjob__item {
        padding-right: 30px; } }
  .dc-career .dc-newjob__text {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
  @media (max-width: 480px) {
    .dc-career .dc-newjob__meta {
      margin-top: 16px; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-career .dc-newjob__meta {
      margin-top: 16px; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-career .dc-newjob__meta {
      margin-top: 16px; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-career .dc-newjob__meta {
      margin-top: 16px; } }
  .dc-career__top {
    align-items: flex-end; }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .dc-career__top {
        margin-bottom: 36px; } }
    @media (max-width: 969px) {
      .dc-career__top {
        margin-bottom: 36px; } }
    @media (max-width: 480px) {
      .dc-career__top {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 36px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career__top {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 36px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career__top {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 36px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career__top {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 36px; } }
  .dc-career__viewmore {
    margin: 40px 0; }
    .dc-career__viewmore .btn {
      padding: 0; }
    @media (max-width: 480px) {
      .dc-career__viewmore {
        margin: 24px 0; }
        .dc-career__viewmore img {
          max-width: 42px; } }
  .dc-career__join {
    background: #f9f9f9;
    padding: 45px 100px; }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .dc-career__join {
        padding: 24px; } }
    @media (max-width: 768px) {
      .dc-career__join {
        padding: 24px; } }
    @media (max-width: 480px) {
      .dc-career__join {
        padding: 24px;
        flex-direction: column; } }
    .dc-career__join-header {
      font-size: 32px;
      font-family: "SFProText Bold", sans-serif;
      margin-bottom: 9px;
      font-weight: 600;
      line-height: 36px; }
      @media (max-width: 480px) {
        .dc-career__join-header {
          font-size: 26px; } }
    .dc-career__join-desc {
      font-size: 15px;
      font-family: "SFProText", sans-serif;
      margin-bottom: 0;
      max-width: 530px;
      line-height: 23px;
      letter-spacing: -0.15px; }
      @media only screen and (max-width: 1024px) and (orientation: landscape) {
        .dc-career__join-desc {
          max-width: 450px; } }
      @media (max-width: 768px) {
        .dc-career__join-desc {
          max-width: 450px; } }
    .dc-career__join-btn {
      font-size: 24px;
      font-weight: 600;
      font-family: "SFProText Bold", sans-serif;
      padding: 0; }
  .dc-career__center .list-inline-item:not(:last-child) {
    margin-right: 24px; }
  .dc-career__center .list-inline-item a {
    font-weight: 600;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 0;
    font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif; }
  .dc-career__center .list-inline-item.active a {
    color: #000; }
    .dc-career__center .list-inline-item.active a::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background: #000;
      width: 100%;
      height: 2px; }
  .dc-career [class*='col-'] .dc-newjob__item {
    height: 350px;
    margin-top: -1px; }
    @media (max-width: 480px) {
      .dc-career [class*='col-'] .dc-newjob__item {
        height: 100%; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career [class*='col-'] .dc-newjob__item {
        height: 100%;
        padding: 26px 30px 28px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career [class*='col-'] .dc-newjob__item {
        height: 100%;
        padding: 26px 30px 28px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career [class*='col-'] .dc-newjob__item {
        height: 100%;
        padding: 26px 30px 28px; } }
    .dc-career [class*='col-'] .dc-newjob__item ul {
      padding-left: 0;
      list-style: none; }
    .dc-career [class*='col-'] .dc-newjob__item ul, .dc-career [class*='col-'] .dc-newjob__item p {
      font-size: 16px; }
  .dc-career [class*='col-'] .dc-newjob__item.disabled {
    opacity: .3; }
    .dc-career [class*='col-'] .dc-newjob__item.disabled:hover {
      background: transparent;
      color: #616161; }
      .dc-career [class*='col-'] .dc-newjob__item.disabled:hover .dc-newjob__header {
        color: #000; }
  .dc-career [class*='col-']:first-child .dc-newjob__item {
    font-weight: 600;
    font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif;
    color: #000; }
    .dc-career [class*='col-']:first-child .dc-newjob__item .dc-newjob__header {
      display: block; }
    .dc-career [class*='col-']:first-child .dc-newjob__item:hover {
      background: transparent; }
      .dc-career [class*='col-']:first-child .dc-newjob__item:hover .dc-newjob__header {
        color: #000; }
  .dc-career [class*='col-']:first-child .dc-newjob__header {
    font-size: 50px;
    line-height: 56px; }
    @media (max-width: 480px) {
      .dc-career [class*='col-']:first-child .dc-newjob__header {
        font-size: 36px;
        line-height: 39px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career [class*='col-']:first-child .dc-newjob__header {
        font-size: 36px;
        line-height: 39px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career [class*='col-']:first-child .dc-newjob__header {
        font-size: 36px;
        line-height: 39px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career [class*='col-']:first-child .dc-newjob__header {
        font-size: 36px;
        line-height: 39px; } }
    .dc-career [class*='col-']:first-child .dc-newjob__header + span {
      font-size: 16px; }
  .dc-career [class*='col-']:first-child .dc-newjob__meta {
    font-size: 18px; }
    .dc-career [class*='col-']:first-child .dc-newjob__meta .list-inline-item::after {
      display: none; }
    .dc-career [class*='col-']:first-child .dc-newjob__meta .list-inline-item.disabled-filter {
      color: rgba(0, 0, 0, 0.25); }
      .dc-career [class*='col-']:first-child .dc-newjob__meta .list-inline-item.disabled-filter span {
        color: rgba(0, 0, 0, 0.25); }
  @media (max-width: 480px) {
    .dc-career [class*='col-']:not(:first-child) .dc-newjob__item {
      border-top: 0; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-career [class*='col-']:not(:first-child) .dc-newjob__item {
      border-top: 0; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-career [class*='col-']:not(:first-child) .dc-newjob__item {
      border-top: 0; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-career [class*='col-']:not(:first-child) .dc-newjob__item {
      border-top: 0; } }
  .dc-career__modal .form-control {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #000;
    padding-left: 0;
    padding-right: 0; }
    .dc-career__modal .form-control:focus {
      box-shadow: none; }
  .dc-career__modal .list-radio {
    transform: translateY(4px);
    position: relative;
    z-index: 1; }
    @media (max-width: 480px) {
      .dc-career__modal .list-radio {
        transform: translateY(2px); } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career__modal .list-radio {
        transform: translateY(0px); } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career__modal .list-radio {
        transform: translateY(0px); } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career__modal .list-radio {
        transform: translateY(0px); } }
  .dc-career__modal .col-form-label {
    margin-top: 16px;
    transform: translateY(5px); }
    @media (max-width: 480px) {
      .dc-career__modal .col-form-label {
        transform: translateY(0); } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career__modal .col-form-label {
        transform: translateY(1px);
        margin-top: 20px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career__modal .col-form-label {
        transform: translateY(1px);
        margin-top: 20px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career__modal .col-form-label {
        transform: translateY(1px);
        margin-top: 20px; } }
  .dc-career__modal-title {
    font-size: 24px;
    color: #222;
    font-family: "SFProText Bold", sans-serif; }
  .dc-career__modal-desc {
    color: #a1a1a1;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 36px; }
  .dc-career__modal-body {
    padding: 0 30px;
    font-family: "SFProText", sans-serif; }
  .dc-career__modal-form [type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
    display: none; }
  .dc-career__modal-btn {
    font-size: 20px;
    width: calc(100% + 60px);
    margin-left: -30px;
    border-radius: 0; }
    .dc-career__modal-btn:hover {
      background-color: #08b33b;
      border-color: #08b33b; }
  .dc-career__modal label.input-cv {
    border-bottom: 1px solid #000;
    cursor: pointer; }

.dc-career-detail {
  padding: 70px 0 100px; }
  @media (max-width: 1170px) {
    .dc-career-detail {
      padding: 50px 0; } }
  @media (max-width: 480px) {
    .dc-career-detail {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-career-detail {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-career-detail {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-career-detail {
      padding: 35px 0; } }
  .dc-career-detail .dc-page-title {
    margin-left: -5px; }
  .dc-career-detail__top {
    border-bottom: 1px solid #e8e7e8;
    padding-bottom: 47px;
    margin-bottom: 6px; }
    @media (max-width: 480px) {
      .dc-career-detail__top {
        padding-bottom: 24px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career-detail__top {
        padding-bottom: 24px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career-detail__top {
        padding-bottom: 24px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career-detail__top {
        padding-bottom: 24px; } }
  .dc-career-detail__name h3 {
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    font-family: "SFProText Bold", sans-serif;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 480px) {
      .dc-career-detail__name h3 {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career-detail__name h3 {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career-detail__name h3 {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career-detail__name h3 {
        font-size: 25px;
        line-height: 32px;
        margin-bottom: 0; } }
  .dc-career-detail__name ul {
    font-size: 14px;
    color: #616161; }
    @media (max-width: 969px) {
      .dc-career-detail__name ul {
        padding: 20px 0; } }
    .dc-career-detail__name ul .list-inline-item {
      position: relative;
      font-family: "SFProText", sans-serif; }
      .dc-career-detail__name ul .list-inline-item:not(:last-child) {
        margin-right: 21px; }
        .dc-career-detail__name ul .list-inline-item:not(:last-child)::after {
          position: absolute;
          content: '';
          right: -13px;
          top: 5px;
          background: #d8d8d8;
          width: 1px;
          height: 12px; }
  .dc-career-detail__social {
    min-width: 135px; }
    .dc-career-detail__social img {
      max-width: 38px; }
  .dc-career-detail__content {
    margin-top: 50px;
    font-family: "SFProText", sans-serif; }
    @media (max-width: 480px) {
      .dc-career-detail__content {
        margin-top: 40px; } }
    @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      .dc-career-detail__content {
        margin-top: 40px; } }
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
      .dc-career-detail__content {
        margin-top: 40px; } }
    .dc-career-detail__content h4 {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 20px;
      font-family: "SFProText Bold", sans-serif; }
      @media (max-width: 480px) {
        .dc-career-detail__content h4 {
          margin-bottom: 12px; } }
      @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        .dc-career-detail__content h4 {
          margin-bottom: 12px; } }
      @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        .dc-career-detail__content h4 {
          margin-bottom: 12px; } }
    .dc-career-detail__content ul {
      color: #616161;
      font-size: 16px;
      line-height: 30px; }
      .dc-career-detail__content ul li {
        position: relative;
        padding-left: 12px;
        list-style: none; }
        .dc-career-detail__content ul li::after {
          position: absolute;
          content: '';
          left: 0;
          top: 12px;
          background: #bfbfbf;
          width: 5px;
          height: 5px;
          border-radius: 50%; }
        @media (max-width: 480px) {
          .dc-career-detail__content ul li {
            letter-spacing: -0.4px; } }
        @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
          .dc-career-detail__content ul li {
            letter-spacing: -0.4px; } }
        @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
          .dc-career-detail__content ul li {
            letter-spacing: -0.4px; } }
    .dc-career-detail__content p {
      color: #616161;
      font-size: 16px;
      line-height: 30px;
      line-height: 26px;
      letter-spacing: -0.16px; }
      @media (max-width: 480px) {
        .dc-career-detail__content p {
          letter-spacing: -0.4px; } }
      @media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        .dc-career-detail__content p {
          letter-spacing: -0.4px; } }
      @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        .dc-career-detail__content p {
          letter-spacing: -0.4px; } }
  .dc-career-detail__backlist {
    font-weight: 600;
    font-family: "SFProText", sans-serif;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 42px;
    margin-top: 16px; }
    @media (max-width: 480px) {
      .dc-career-detail__backlist {
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career-detail__backlist {
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career-detail__backlist {
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career-detail__backlist {
        margin-bottom: 0; } }
  .dc-career-detail__apply {
    border-top: 1px solid #e8e7e8;
    padding: 15px 0 50px;
    text-align: center;
    margin-top: 66px;
    background: #fff;
    position: fixed;
    left: 0;
    background: #fff;
    bottom: 0;
    width: 100%;
    z-index: 1; }
    .dc-career-detail__apply.remove-fixed {
      position: relative; }
      @media (max-width: 480px) {
        .dc-career-detail__apply.remove-fixed {
          position: fixed; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-career-detail__apply.remove-fixed {
          position: fixed; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-career-detail__apply.remove-fixed {
          position: fixed; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-career-detail__apply.remove-fixed {
          position: fixed; } }
    @media (max-width: 1170px) {
      .dc-career-detail__apply {
        padding: 15px 0 30px; } }
    @media (max-width: 480px) {
      .dc-career-detail__apply {
        padding: 0;
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career-detail__apply {
        padding: 0;
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career-detail__apply {
        padding: 0;
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career-detail__apply {
        padding: 0;
        margin-top: 0; } }
    .dc-career-detail__apply-btn {
      font-family: "SFProText Bold", sans-serif;
      width: 580px;
      height: 80px;
      line-height: 60px;
      font-weight: 600;
      margin: 0 auto;
      font-size: 20px; }
    @media (max-width: 1170px) {
      .dc-career-detail__apply a,
      .dc-career-detail__apply .btn {
        width: 50vw;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        font-size: 18px;
        font-family: "SFProText Bold", sans-serif; } }
    @media (max-width: 480px) {
      .dc-career-detail__apply a,
      .dc-career-detail__apply .btn {
        line-height: 34px;
        letter-spacing: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career-detail__apply a,
      .dc-career-detail__apply .btn {
        line-height: 34px;
        letter-spacing: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career-detail__apply a,
      .dc-career-detail__apply .btn {
        line-height: 34px;
        letter-spacing: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career-detail__apply a,
      .dc-career-detail__apply .btn {
        line-height: 34px;
        letter-spacing: 0; } }
    .dc-career-detail__apply .btn-secondary {
      background-color: #d4d4d4; }
      .dc-career-detail__apply .btn-secondary:hover {
        border-color: #d4d4d4; }
      .dc-career-detail__apply .btn-secondary:active {
        background-color: #d4d4d4 !important;
        border-color: #d4d4d4 !important; }
    .dc-career-detail__apply-mobile {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1; }
  .dc-career-detail .dc-career__join {
    display: none !important; }
    .dc-career-detail .dc-career__join.show {
      display: flex !important; }
      @media (max-width: 480px) {
        .dc-career-detail .dc-career__join.show {
          display: none !important; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-career-detail .dc-career__join.show {
          display: none !important; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-career-detail .dc-career__join.show {
          display: none !important; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-career-detail .dc-career__join.show {
          display: none !important; } }
    @media (max-width: 480px) {
      .dc-career-detail .dc-career__join {
        display: none !important; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-career-detail .dc-career__join {
        display: none !important; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-career-detail .dc-career__join {
        display: none !important; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-career-detail .dc-career__join {
        display: none !important; } }

.dc-page-news {
  padding: 70px 0; }
  @media (max-width: 480px) {
    .dc-page-news {
      padding: 36px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-page-news {
      padding: 36px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-page-news {
      padding: 36px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-page-news {
      padding: 36px 0; } }
  .dc-page-news .dc-news__desc {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 480px) {
      .dc-page-news .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
  .dc-page-news [class*='col-']:first-child .dc-news__item {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 80px; }
    @media (max-width: 1170px) {
      .dc-page-news [class*='col-']:first-child .dc-news__item {
        display: block;
        margin: 40px 0; } }
    @media (max-width: 480px) {
      .dc-page-news [class*='col-']:first-child .dc-news__item {
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news [class*='col-']:first-child .dc-news__item {
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news [class*='col-']:first-child .dc-news__item {
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news [class*='col-']:first-child .dc-news__item {
        margin-top: 0; } }
    .dc-page-news [class*='col-']:first-child .dc-news__item::before, .dc-page-news [class*='col-']:first-child .dc-news__item::after {
      content: '';
      position: absolute;
      right: 0;
      width: 350px; }
      @media (max-width: 1170px) {
        .dc-page-news [class*='col-']:first-child .dc-news__item::before, .dc-page-news [class*='col-']:first-child .dc-news__item::after {
          display: none; } }
    .dc-page-news [class*='col-']:first-child .dc-news__item::before {
      height: 4px;
      background: #000;
      top: 0; }
    .dc-page-news [class*='col-']:first-child .dc-news__item::after {
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      bottom: 0; }
  .dc-page-news [class*='col-']:first-child .dc-news__desc {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
    @media (max-width: 480px) {
      .dc-page-news [class*='col-']:first-child .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news [class*='col-']:first-child .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news [class*='col-']:first-child .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news [class*='col-']:first-child .dc-news__desc {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis; } }
  .dc-page-news [class*='col-']:first-child .dc-news__img {
    min-width: 790px;
    height: 418px;
    margin-right: 30px; }
    @media (max-width: 1170px) {
      .dc-page-news [class*='col-']:first-child .dc-news__img {
        min-width: unset;
        margin-right: 0; } }
    @media (max-width: 480px) {
      .dc-page-news [class*='col-']:first-child .dc-news__img {
        width: 100%;
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news [class*='col-']:first-child .dc-news__img {
        width: 100%;
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news [class*='col-']:first-child .dc-news__img {
        width: 100%;
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news [class*='col-']:first-child .dc-news__img {
        width: 100%;
        height: 160px; } }
  .dc-page-news [class*='col-']:not(:first-child) .dc-news__item {
    margin-bottom: 66px; }
    @media (max-width: 480px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__item {
        margin-bottom: 40px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__item {
        margin-bottom: 40px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__item {
        margin-bottom: 40px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__item {
        margin-bottom: 40px; } }
  .dc-page-news [class*='col-']:not(:first-child) .dc-news__img {
    height: 250px; }
    @media (max-width: 480px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__img {
        height: 160px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news [class*='col-']:not(:first-child) .dc-news__img {
        height: 160px; } }
  .dc-page-news__viewmore {
    margin: 21px 0 0; }
    @media (max-width: 480px) {
      .dc-page-news__viewmore {
        margin: 0 0 10px; }
        .dc-page-news__viewmore img {
          max-width: 42px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-news__viewmore {
        margin: 0 0 10px; }
        .dc-page-news__viewmore img {
          max-width: 42px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-news__viewmore {
        margin: 0 0 10px; }
        .dc-page-news__viewmore img {
          max-width: 42px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-news__viewmore {
        margin: 0 0 10px; }
        .dc-page-news__viewmore img {
          max-width: 42px; } }

.dc-news-detail {
  padding: 70px 0 0; }
  @media (max-width: 1170px) {
    .dc-news-detail {
      padding: 50px 0 0; } }
  .dc-news-detail__name {
    font-family: "SFProText", sans-serif; }
  .dc-news-detail__top {
    border-bottom: 1px solid #e8e7e8;
    padding-bottom: 47px;
    margin-bottom: 6px; }
    @media (max-width: 480px) {
      .dc-news-detail__top {
        padding-bottom: 24px; } }
    @media only screen and (max-width: 375px) {
      .dc-news-detail__top {
        flex-direction: column; } }
  .dc-news-detail__content {
    margin-top: 50px;
    font-family: "SFProText", sans-serif; }
    @media (max-width: 480px) {
      .dc-news-detail__content {
        margin-top: 30px; } }
    .dc-news-detail__content h4 {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 18px;
      font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif; }
    .dc-news-detail__content ul {
      color: #1e1e1e;
      font-size: 16px;
      line-height: 30px;
      font-weight: 600;
      list-style: none; }
      .dc-news-detail__content ul li {
        position: relative;
        padding-left: 12px; }
        .dc-news-detail__content ul li::after {
          position: absolute;
          content: '';
          left: 0;
          top: 12px;
          background: #1e1e1e;
          width: 5px;
          height: 5px;
          border-radius: 50%; }
    .dc-news-detail__content p {
      color: #616161;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 0; }
    .dc-news-detail__content img {
      width: 100% !important;
      max-width: 100% !important;
      height: 100%;
      object-fit: cover; }
  .dc-news-detail__title {
    font-size: 34px;
    font-weight: 600;
    line-height: 42px;
    font-family: "LINESeedBold", -apple-system, BlinkMacSystemFont, sans-serif; }
    @media (max-width: 1170px) {
      .dc-news-detail__title {
        margin-bottom: 24px; } }
    @media (max-width: 480px) {
      .dc-news-detail__title {
        font-size: 24px; } }
  .dc-news-detail__name ul {
    font-size: 14px;
    color: #616161;
    margin-top: 8px; }
    @media (max-width: 969px) {
      .dc-news-detail__name ul {
        padding: 10px 0; } }
    .dc-news-detail__name ul .list-inline-item {
      position: relative; }
      .dc-news-detail__name ul .list-inline-item:not(:last-child) {
        margin-right: 21px; }
        .dc-news-detail__name ul .list-inline-item:not(:last-child)::after {
          position: absolute;
          content: '';
          right: -11px;
          top: 5px;
          background: #616161;
          width: 1px;
          height: 12px; }
  .dc-news-detail__social {
    min-width: 145px; }
    .dc-news-detail__social a {
      display: block; }
    .dc-news-detail__social img {
      max-width: 38px; }
  .dc-news-detail__bottom {
    border-top: 1px solid #e8e7e8;
    padding: 15px 0 50px;
    text-align: center;
    margin-top: 50px; }
    @media (max-width: 1170px) {
      .dc-news-detail__bottom {
        padding: 15px 0 30px;
        margin-top: 24px; } }
  .dc-news-detail__backlist {
    font-weight: 600;
    font-family: "SFProText Bold", sans-serif;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end; }

.dc-page-culture {
  padding: 70px 0 100px; }
  @media (max-width: 1170px) {
    .dc-page-culture {
      padding: 36px 0 50px; } }
  .dc-page-culture__welfare [class*='col-']:nth-child(2) {
    max-width: 490px; }
    @media (max-width: 1170px) {
      .dc-page-culture__welfare [class*='col-']:nth-child(2) {
        max-width: 100%; } }
  .dc-page-culture__text {
    color: #616161;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.16px; }
    @media (max-width: 480px) {
      .dc-page-culture__text {
        line-height: 24px;
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-culture__text {
        line-height: 24px;
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-culture__text {
        line-height: 24px;
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-culture__text {
        line-height: 24px;
        margin-bottom: 0; } }
  .dc-page-culture ul {
    color: #616161;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 26px;
    letter-spacing: -0.16px;
    padding-left: 0;
    list-style: none; }
  .dc-page-culture__intro {
    margin-bottom: 50px; }
    @media (max-width: 1170px) {
      .dc-page-culture__intro {
        margin-bottom: 34px;
        margin-top: 32px; } }
    @media (max-width: 480px) {
      .dc-page-culture__intro {
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-culture__intro {
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-culture__intro {
        margin-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-culture__intro {
        margin-top: 0; } }
  .dc-page-culture__item-img img {
    width: 100%;
    height: 494px;
    object-fit: cover; }
    @media (max-width: 1170px) {
      .dc-page-culture__item-img img {
        height: 240px; } }
  .dc-page-culture__item-body {
    padding: 33px 30px 100px 125px; }
    @media (max-width: 1170px) {
      .dc-page-culture__item-body {
        padding: 30px 30px 70px 100px; } }
    @media (max-width: 1170px) {
      .dc-page-culture__item-body {
        padding: 16px 0 40px; } }
  .dc-page-culture__item-title {
    font-size: 35px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: -0.35px;
    font-family: "SFProText Bold", sans-serif; }
    @media (max-width: 1170px) {
      .dc-page-culture__item-title {
        font-size: 20px; } }
  .dc-page-culture__item-category {
    color: #08b33b;
    margin: 22px 0;
    display: block; }
    @media (max-width: 1170px) {
      .dc-page-culture__item-category {
        margin: 10px 0; } }
  .dc-page-culture__left .dc-page-culture__item:nth-child(2) {
    padding-left: 125px; }
    @media (max-width: 1170px) {
      .dc-page-culture__left .dc-page-culture__item:nth-child(2) {
        padding-left: 100px; } }
    @media (max-width: 1170px) {
      .dc-page-culture__left .dc-page-culture__item:nth-child(2) {
        padding-left: 0; } }
    .dc-page-culture__left .dc-page-culture__item:nth-child(2) .dc-page-culture__item-body {
      padding-left: 0; }
    .dc-page-culture__left .dc-page-culture__item:nth-child(2) .dc-page-culture__item-img img {
      height: 480px; }
      @media (max-width: 1170px) {
        .dc-page-culture__left .dc-page-culture__item:nth-child(2) .dc-page-culture__item-img img {
          height: 240px; } }
  .dc-page-culture__right .dc-page-culture__item-body {
    padding-left: 0; }
  .dc-page-culture__right .dc-page-culture__item-img img {
    height: 620px; }
    @media (max-width: 1170px) {
      .dc-page-culture__right .dc-page-culture__item-img img {
        height: 240px; } }
  .dc-page-culture__right .dc-page-culture__item:nth-child(2) .dc-page-culture__item-img img {
    height: 480px; }
    @media (max-width: 1170px) {
      .dc-page-culture__right .dc-page-culture__item:nth-child(2) .dc-page-culture__item-img img {
        height: 240px; } }
  .dc-page-culture__office {
    font-family: "SFProText", sans-serif; }
    @media (max-width: 480px) {
      .dc-page-culture__office .dc-page-culture__office-intro {
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-page-culture__office .dc-page-culture__office-intro {
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-page-culture__office .dc-page-culture__office-intro {
        margin-bottom: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-page-culture__office .dc-page-culture__office-intro {
        margin-bottom: 0; } }
    .dc-page-culture__office-banner img {
      width: 100%;
      height: 280px; }
      @media (max-width: 1170px) {
        .dc-page-culture__office-banner img {
          height: 150px; } }
    .dc-page-culture__office-intro {
      padding-left: 25px;
      margin-top: -72px; }
      @media (max-width: 480px) {
        .dc-page-culture__office-intro {
          padding-left: 0; } }
      .dc-page-culture__office-intro + ul {
        padding-left: 25px; }
        @media (max-width: 480px) {
          .dc-page-culture__office-intro + ul {
            padding-left: 0; } }
    .dc-page-culture__office-header {
      font-size: 70px;
      font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif;
      line-height: 76px;
      margin-top: -32px;
      margin-bottom: 0; }
      @media (max-width: 1170px) {
        .dc-page-culture__office-header {
          font-size: 40px;
          line-height: 42px;
          margin-top: -24px; } }
    @media (max-width: 1170px) {
      .dc-page-culture__office-intro {
        margin-top: 14px; } }
    @media (max-width: 1170px) {
      .dc-page-culture__office-intro {
        margin-bottom: 34px; } }
    .dc-page-culture__office-item-title {
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 14px;
      font-family: "SFProText Bold", sans-serif;
      line-height: 36px; }
      @media (max-width: 1170px) {
        .dc-page-culture__office-item-title {
          font-size: 26px;
          margin-bottom: 10px; } }
    .dc-page-culture__office-item-body {
      padding: 0 100px 0 41px;
      border: 1px solid #e8e7e8;
      min-height: 360px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      @media (max-width: 1170px) {
        .dc-page-culture__office-item-body {
          padding: 28px 30px;
          min-height: 335px; } }
      @media (max-width: 1170px) {
        .dc-page-culture__office-item-body {
          min-height: unset; } }
    .dc-page-culture__office-item-img img {
      width: 100%;
      height: 360px;
      object-fit: cover; }
      @media (max-width: 1170px) {
        .dc-page-culture__office-item-img img {
          height: 200px; } }
    .dc-page-culture__office-content .dc-page-culture__office-item:nth-child(2) {
      flex-direction: row-reverse; }

.dc-apply {
  padding: 32px 0 0; }
  .dc-apply__wrap {
    width: 800px; }
  .dc-apply__form-item {
    padding: 24px 0; }
    .dc-apply__form-item:not(:last-child) {
      border-bottom: 1px solid #e8e7e8; }
  .dc-apply__form h3 {
    font-size: 32px;
    margin-bottom: 24px; }
    .dc-apply__form h3 .required {
      color: #08b33b; }
  .dc-apply__form p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
    color: #616161; }
  .dc-apply .form-control {
    color: #1e1e1e; }
  .dc-apply .col-form-label,
  .dc-apply .form-check-label {
    font-size: 16px;
    color: #616161; }
  .dc-apply__review table th {
    width: 250px; }

.dc-about {
  padding: 70px 0 100px; }
  @media (max-width: 480px) {
    .dc-about {
      padding: 35px 0 21px; } }
  .dc-about .dc-page-title {
    margin-bottom: 24px; }
    @media (max-width: 480px) {
      .dc-about .dc-page-title {
        margin-bottom: 16px; } }
  .dc-about .dc-arrow {
    border-top: 1px solid #08b33b;
    border-left: 1px solid #08b33b;
    top: 9px;
    left: calc(100% + 6px); }
  .dc-about__desc {
    font-size: 16px;
    font-family: "SFProText", sans-serif;
    line-height: 21px;
    color: #616161;
    margin-bottom: 57px; }
    @media (max-width: 480px) {
      .dc-about__desc {
        margin-bottom: 50px;
        line-height: 24px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-about__desc {
        margin-bottom: 50px;
        line-height: 24px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-about__desc {
        margin-bottom: 50px;
        line-height: 24px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-about__desc {
        margin-bottom: 50px;
        line-height: 24px; } }
  .dc-about__office [class*='col-'] {
    padding-left: 20px;
    padding-right: 20px; }
  .dc-about__office-item {
    color: #1e1e1e; }
    @media (max-width: 1170px) {
      .dc-about__office-item {
        margin-bottom: 40px; } }
    .dc-about__office-item-img img {
      width: 100%;
      height: 330px;
      object-fit: cover; }
      @media (max-width: 480px) {
        .dc-about__office-item-img img {
          height: 200px; } }
    .dc-about__office-item-title {
      font-size: 26px;
      font-weight: 600;
      display: block;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: "SFProText Bold", sans-serif; }
      @media (max-width: 480px) {
        .dc-about__office-item-title {
          font-size: 20px; } }
    .dc-about__office-item-body {
      padding: 24px 0 0; }
      @media (max-width: 480px) {
        .dc-about__office-item-body {
          padding: 17px 0 0; } }
    .dc-about__office-item-desc {
      font-size: 16px;
      font-family: "SFProText", sans-serif;
      line-height: 21px;
      margin: 13px 0 25px; }
      @media (max-width: 480px) {
        .dc-about__office-item-desc {
          margin: 7px 0 11px;
          font-size: 15px; } }
    .dc-about__office-item-link {
      font-size: 16px;
      font-family: "SFProText Medium", sans-serif;
      color: #08b33b;
      letter-spacing: 0.46px;
      position: relative;
      display: inline-block;
      font-weight: 500; }
      @media (max-width: 480px) {
        .dc-about__office-item-link {
          letter-spacing: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-about__office-item-link {
          letter-spacing: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-about__office-item-link {
          letter-spacing: 0; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-about__office-item-link {
          letter-spacing: 0; } }
      .dc-about__office-item-link:hover {
        color: #08b33b; }
  .dc-about__header {
    margin-top: 82px;
    margin-bottom: 40px;
    font-size: 32px;
    font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif;
    line-height: 40px; }
    @media (max-width: 1170px) {
      .dc-about__header {
        margin-top: 0px; } }
    @media (max-width: 480px) {
      .dc-about__header {
        margin-bottom: 25px; } }
  .dc-about__direction [class*='col-']:nth-child(2) {
    margin-left: -1px; }
    @media (max-width: 969px) {
      .dc-about__direction [class*='col-']:nth-child(2) {
        margin-left: 0;
        margin-top: -1px; } }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .dc-about__direction [class*='col-']:nth-child(2) {
        margin-left: 0;
        margin-top: -1px; } }
  .dc-about__direction-item {
    border: 1px solid #e2e2e2;
    padding: 50px;
    height: 100%; }
    @media (max-width: 969px) {
      .dc-about__direction-item {
        padding: 30px; } }
    .dc-about__direction-item iframe {
      width: 100%;
      height: 273px;
      border: 0; }
    .dc-about__direction-item-title {
      font-size: 30px;
      font-weight: 600;
      font-family: "SFProText Bold", sans-serif;
      letter-spacing: -0.3px;
      color: #1e1e1e;
      margin: 48px 0 24px; }
      @media (max-width: 480px) {
        .dc-about__direction-item-title {
          font-size: 26px;
          margin: 24px 0;
          letter-spacing: 0;
          line-height: normal; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-about__direction-item-title {
          font-size: 26px;
          margin: 24px 0;
          letter-spacing: 0;
          line-height: normal; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-about__direction-item-title {
          font-size: 26px;
          margin: 24px 0;
          letter-spacing: 0;
          line-height: normal; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-about__direction-item-title {
          font-size: 26px;
          margin: 24px 0;
          letter-spacing: 0;
          line-height: normal; } }
    .dc-about__direction-item-info {
      font-size: 16px;
      font-family: "SFProText", sans-serif;
      line-height: 25px;
      color: #616161;
      margin-bottom: 16px;
      display: flex; }
      .dc-about__direction-item-info a {
        color: #616161; }
        .dc-about__direction-item-info a:hover {
          color: #616161; }
      .dc-about__direction-item-info img {
        min-width: 23px;
        width: 23px;
        height: 23px;
        object-fit: cover;
        margin-top: 2px;
        margin-right: 10px; }
    .dc-about__direction-item-social {
      margin-top: 32px; }
      .dc-about__direction-item-social img {
        width: 38px;
        height: 38px;
        object-fit: cover; }

.dc-faq {
  padding: 70px 0 160px; }
  @media (max-width: 969px) {
    .dc-faq {
      padding: 35px 0 55px; } }
  .dc-faq__title {
    font-size: 20px;
    font-weight: 600;
    color: #000; }
  .dc-faq__box {
    letter-spacing: -0.46px; }
    @media (max-width: 480px) {
      .dc-faq__box {
        border-bottom: 0; } }
    .dc-faq__box:not(:first-child) {
      border-top: 1px solid #ddd;
      padding-top: 32px; }
      @media (max-width: 969px) {
        .dc-faq__box:not(:first-child) {
          display: none; } }
    @media (max-width: 969px) {
      .dc-faq__box:first-child {
        padding-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-faq__box:first-child {
        padding-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-faq__box:first-child {
        padding-top: 0; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-faq__box:first-child {
        padding-top: 0; } }
    .dc-faq__box:last-child {
      border-bottom: 0; }
  .dc-faq__item {
    margin-bottom: 24px;
    border: 0; }
    @media (max-width: 480px) {
      .dc-faq__item {
        border-bottom: 1px solid #ddd; } }
  .dc-faq__header {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 13px;
    font-size: 20px;
    font-weight: 500;
    font-family: "SFProText Medium", sans-serif;
    color: #000;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    transition: all .2s ease;
    letter-spacing: -0.46px; }
    @media (max-width: 480px) {
      .dc-faq__header {
        font-size: 16px;
        padding-bottom: 24px; } }
    .dc-faq__header span {
      width: calc(100% - 25px);
      display: inline-block; }
    .dc-faq__header::after {
      position: absolute;
      top: 10px;
      right: 0;
      width: 20px;
      height: 12px;
      display: block;
      content: "";
      background-image: url("../images/ic-faq-arrow-up.svg");
      background-repeat: no-repeat;
      transition: all .3s ease; }
      @media (max-width: 969px) {
        .dc-faq__header::after {
          top: 9px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-faq__header::after {
          top: 9px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-faq__header::after {
          top: 9px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-faq__header::after {
          top: 9px; } }
    .dc-faq__header:not(.collapsed)::after {
      transform: rotate(180deg); }
  .dc-faq__text {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.73;
    letter-spacing: -0.4px;
    color: #616161;
    padding-right: 60px;
    text-align: left; }
    @media (max-width: 969px) {
      .dc-faq__text {
        padding-right: 35px; } }
  .dc-faq__link {
    display: inline-block;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 2.19;
    letter-spacing: -0.4px;
    color: #000; }
    @media (max-width: 480px) {
      .dc-faq__link {
        margin-top: 0; } }
  .dc-faq__select {
    width: 140px;
    position: relative; }
    @media (max-width: 768px) {
      .dc-faq__select {
        margin-top: 32px;
        margin-bottom: 32px; } }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      .dc-faq__select {
        margin-bottom: 32px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-faq__select {
        margin-top: 32px;
        margin-bottom: 32px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-faq__select {
        margin-top: 32px;
        margin-bottom: 32px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-faq__select {
        margin-top: 32px;
        margin-bottom: 32px; } }
    .dc-faq__select::after {
      position: absolute;
      content: '';
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      background-color: #fff;
      background-image: url("../images/ic-triangle.svg");
      background-repeat: no-repeat;
      background-position: center; }
    .dc-faq__select select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      font-weight: 600;
      letter-spacing: -0.33px;
      font-size: 13px;
      color: #000;
      border: 1px solid #e1e1e1; }

.dc-philosophy {
  padding: 70px 0 100px; }
  @media (max-width: 480px) {
    .dc-philosophy {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-philosophy {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-philosophy {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-philosophy {
      padding: 35px 0; } }
  .dc-philosophy__tabs {
    margin-top: 60px; }
    @media (max-width: 480px) {
      .dc-philosophy__tabs {
        margin-top: 0; } }
    .dc-philosophy__tabs .nav-tabs {
      border-bottom: 0; }
      .dc-philosophy__tabs .nav-tabs .nav-item {
        text-align: center; }
        @media (max-width: 480px) {
          .dc-philosophy__tabs .nav-tabs .nav-item:not(:first-child) {
            margin-top: 10px; } }
        @media only screen and (orientation: landscape) and (max-width: 812px) {
          .dc-philosophy__tabs .nav-tabs .nav-item:not(:first-child) {
            margin-top: 10px; } }
        @media only screen and (orientation: landscape) and (max-width: 736px) {
          .dc-philosophy__tabs .nav-tabs .nav-item:not(:first-child) {
            margin-top: 10px; } }
        @media only screen and (orientation: landscape) and (max-width: 667px) {
          .dc-philosophy__tabs .nav-tabs .nav-item:not(:first-child) {
            margin-top: 10px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-philosophy__tabs .nav-tabs .nav-item .nav-link {
          display: inline-block; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-philosophy__tabs .nav-tabs .nav-item .nav-link {
          display: inline-block; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-philosophy__tabs .nav-tabs .nav-item .nav-link {
          display: inline-block; } }
      .dc-philosophy__tabs .nav-tabs .nav-link {
        padding: 15px 0;
        font-weight: 600;
        font-size: 18px;
        border: 0;
        border-bottom: 2px solid transparent; }
        @media (max-width: 480px) {
          .dc-philosophy__tabs .nav-tabs .nav-link {
            display: inline-block;
            padding: 5px 0; } }
        .dc-philosophy__tabs .nav-tabs .nav-link.active {
          color: #08b33b;
          border-bottom: 2px solid #08b33b; }
    .dc-philosophy__tabs .tab-content {
      margin-top: 60px; }
      @media (max-width: 480px) {
        .dc-philosophy__tabs .tab-content {
          margin-top: 35px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-philosophy__tabs .tab-content {
          margin-top: 35px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-philosophy__tabs .tab-content {
          margin-top: 35px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-philosophy__tabs .tab-content {
          margin-top: 35px; } }
  .dc-philosophy__content {
    max-width: 1000px;
    margin: 0 auto;
    color: #1e1e1e; }
    @media (max-width: 969px) {
      .dc-philosophy__content {
        max-width: 100%; } }
  .dc-philosophy__mission-intro h3 {
    font-size: 22px;
    margin-bottom: 24px; }
  .dc-philosophy__mission-intro p {
    font-size: 17px;
    margin-bottom: 0;
    line-height: 30px; }
    @media (max-width: 480px) {
      .dc-philosophy__mission-intro p {
        font-size: 16px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-philosophy__mission-intro p {
        font-size: 16px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-philosophy__mission-intro p {
        font-size: 16px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-philosophy__mission-intro p {
        font-size: 16px; } }
  .dc-philosophy__mission-text, .dc-philosophy__mission-vision {
    margin-top: 80px; }
    @media (max-width: 480px) {
      .dc-philosophy__mission-text, .dc-philosophy__mission-vision {
        margin-top: 35px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-philosophy__mission-text, .dc-philosophy__mission-vision {
        margin-top: 35px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-philosophy__mission-text, .dc-philosophy__mission-vision {
        margin-top: 35px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-philosophy__mission-text, .dc-philosophy__mission-vision {
        margin-top: 35px; } }
    .dc-philosophy__mission-text h2, .dc-philosophy__mission-vision h2 {
      font-size: 40px;
      text-align: center; }
      @media (max-width: 480px) {
        .dc-philosophy__mission-text h2, .dc-philosophy__mission-vision h2 {
          font-size: 32px; } }
      @media only screen and (orientation: landscape) and (max-width: 812px) {
        .dc-philosophy__mission-text h2, .dc-philosophy__mission-vision h2 {
          font-size: 32px; } }
      @media only screen and (orientation: landscape) and (max-width: 736px) {
        .dc-philosophy__mission-text h2, .dc-philosophy__mission-vision h2 {
          font-size: 32px; } }
      @media only screen and (orientation: landscape) and (max-width: 667px) {
        .dc-philosophy__mission-text h2, .dc-philosophy__mission-vision h2 {
          font-size: 32px; } }
    .dc-philosophy__mission-text h4, .dc-philosophy__mission-vision h4 {
      color: #08b33b;
      font-size: 20px;
      text-align: center; }
    .dc-philosophy__mission-text h5, .dc-philosophy__mission-vision h5 {
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      font-family: "LINESeedExtraBold", -apple-system, BlinkMacSystemFont, sans-serif;
      margin-bottom: 32px; }
    .dc-philosophy__mission-text p, .dc-philosophy__mission-vision p {
      font-size: 17px;
      text-align: left; }
  .dc-philosophy__item, .dc-philosophy__style, .dc-philosophy__code {
    margin-top: 60px; }
    @media (max-width: 480px) {
      .dc-philosophy__item, .dc-philosophy__style, .dc-philosophy__code {
        margin-top: 35px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-philosophy__item, .dc-philosophy__style, .dc-philosophy__code {
        margin-top: 35px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-philosophy__item, .dc-philosophy__style, .dc-philosophy__code {
        margin-top: 35px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-philosophy__item, .dc-philosophy__style, .dc-philosophy__code {
        margin-top: 35px; } }
    .dc-philosophy__item h3, .dc-philosophy__style h3, .dc-philosophy__code h3 {
      font-size: 32px;
      font-weight: 700; }
    .dc-philosophy__item h4, .dc-philosophy__style h4, .dc-philosophy__code h4 {
      font-size: 20px;
      color: #08b33b;
      margin-bottom: 32px; }
    .dc-philosophy__item p, .dc-philosophy__style p, .dc-philosophy__code p {
      font-size: 17px;
      line-height: 30px;
      text-align: left; }
    .dc-philosophy__item a, .dc-philosophy__style a, .dc-philosophy__code a {
      font-size: 17px;
      color: #616161;
      text-decoration: underline; }

.dc-policy {
  padding: 70px 0;
  font-family: "SFProText", sans-serif; }
  @media (max-width: 480px) {
    .dc-policy {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 812px) {
    .dc-policy {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 736px) {
    .dc-policy {
      padding: 35px 0; } }
  @media only screen and (orientation: landscape) and (max-width: 667px) {
    .dc-policy {
      padding: 35px 0; } }
  .dc-policy__content ul,
  .dc-policy__content p {
    color: #616161;
    font-size: 16px; }
  .dc-policy__item {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px solid #ddd; }
    @media (max-width: 480px) {
      .dc-policy__item {
        margin-top: 40px;
        padding-top: 40px; } }
    @media only screen and (orientation: landscape) and (max-width: 812px) {
      .dc-policy__item {
        margin-top: 40px;
        padding-top: 40px; } }
    @media only screen and (orientation: landscape) and (max-width: 736px) {
      .dc-policy__item {
        margin-top: 40px;
        padding-top: 40px; } }
    @media only screen and (orientation: landscape) and (max-width: 667px) {
      .dc-policy__item {
        margin-top: 40px;
        padding-top: 40px; } }
    .dc-policy__item h4 {
      font-size: 20px;
      color: #000;
      font-weight: 700;
      margin-bottom: 24px; }
